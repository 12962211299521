<template>
  <Teleport to="#modals">
    <div class="promo-modal-overlay modal" :class="{ open: show }">
      <div class="dosiin_promo-modal-container promo-modal-container">
        <div class="promo-modal-header">
          <div class="promo-modal_cancel" @click="closeMoDal">Hủy</div>
          <div class="promo-modal_title">Khuyến mãi</div>
        </div>
        <div class="promo-modal-body">
          <div class="coupon-form" v-if="currentUser">
            <p>
              Chào <b v-text="currentUser.firstname"></b>, đây là danh sách các
              <b>phiếu giảm giá </b>mà bạn có thể áp dụng. Bạn có thể dùng phiếu giảm giá
              của <b>Dosiin</b> gộp với phiếu giảm giá của <b>Thương Hiệu</b> bạn mua (nếu
              thoả điều kiện)
            </p>
          </div>
          <PromotionsList :promotions="promotions" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { toRefs, ref, watch, inject } from "vue";
import PromotionsList from "@/components/common/PromotionsList";

export default {
  components: {
    PromotionsList,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    promotions: {
      type: Array,
      default: [],
    },
    buttonType: {
      type: String,
      default: "use",
    },
  },
  setup(props, { emit }) {
    const currentUser = inject("currentUser");
    const show = ref(props.modalValue);

    watch(
      () => props.modelValue,
      (isOpen) => {
        show.value = isOpen;
      }
    );

    const closeMoDal = () => {
      show.value = !show.value;
      emit("update:modelValue", show.value);
    };

    return {
      show,
      closeMoDal,
      currentUser,
      ...toRefs(props),
    };
  },
};
</script>
