<template>
  <Intersection @onIntersection="onIntersection()">
    <div class="section-title slide-view-container" v-if="changeGrid">
      <Slider
        v-model="gridLayout"
        class="view-container"
        :min="0"
        :max="2"
        :step="-1"
        :tooltips="false"
      />
    </div>

    <div ref="productBox">
      <ProductLoading :wrapperClass="'grid ' + gridClass" v-if="!state.init" />

      <ais-instant-search
        v-else
        :search-client="searchClientKeys"
        index-name="dev_products"
        :middlewares="middlewares"
      >
        <ais-configure
          :hitsPerPage="60"
          :filters="query && query.category_id ? `category_id:${query.category_id}` : ''"
        />
        <!-- :cache="cacheAlgolia" -->
        <ais-infinite-hits>
          <template v-slot="{ items, refinePrevious, refineNext, isLastPage, sendEvent }">
            <div class="grid" :class="gridClass">
              <div class="grid-item" v-for="(product, i) in items" :key="i">
                <ProductItem
                  :product="product"
                  :showRating="true"
                  @clickProductAlgoliaEvent="
                    sendEvent('click', product, 'product - ' + $route.name + ' - clicked')
                  "
                />
              </div>
            </div>

            <button
              class="button primary-button"
              style="width: 150px; margin: auto"
              :disabled="isLastPage"
              @click="refineNext"
            >
              <span class="primary-link_text">Xem thêm</span>
            </button>
          </template>
        </ais-infinite-hits>
        <!-- <AlgoligaPagination /> -->
      </ais-instant-search>
    </div>
  </Intersection>
</template>

<script>
import AlgoligaPagination from "@/algolia/search/desktop/Pagination";
import ProductItem from "@/components/product/ProductItem";
import Slider from "@vueform/slider";
import { ref, watch, inject, reactive } from "vue";
import { productGridClass } from "@/utlis/global";
import ProductLoading from "@/components/skeletons/Products";

// import { createInfiniteHitsSessionStorageCache } from "instantsearch.js/es/lib/infiniteHitsCache";
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { _getCookie } from "@/utlis/cookie";
import aa from "search-insights";
export default {
  components: {
    Slider,
    ProductItem,
    ProductLoading,
    AlgoligaPagination,
  },
  props: {
    gridClass: {
      type: String,
      default: "grid-20",
    },
    changeGrid: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Number,
      default: 1,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const state = reactive({
      init: false,
    });

    const gridLayout = ref(props.grid);
    const gridClass = ref(props.gridClass);
    const currentUser = inject("currentUser");
    const insightsMiddleware = createInsightsMiddleware({
      insightsClient: aa,
    });

    aa("setUserToken", currentUser.value ? currentUser.value.user_id : _getCookie("dsi"));
    watch(
      () => gridLayout.value,
      (gridId) => {
        if (gridId < 0.5) gridId = 0;
        if (gridId > 0.5 && gridId < 1) gridId = 1;
        if (gridId > 1 && gridId < 1.5) gridId = 1;
        if (gridId > 1.5 && gridId <= 2) gridId = 2;

        const gridSelected = productGridClass[gridId];
        if (gridSelected) gridClass.value = gridSelected;
      },
      { immediate: props.changeGrid }
    );

    const onIntersection = () => {
      state.init = true;
    };

    return {
      state,
      gridLayout,
      gridClass,
      middlewares: [insightsMiddleware],
      onIntersection,
      // cacheAlgolia: createInfiniteHitsSessionStorageCache(),
    };
  },
};
</script>
