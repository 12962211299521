import Api from "@/services/Api";
import { _getCookie } from "@/utlis/cookie";
import store  from "../store/index";
const aa = require("search-insights");

aa("init", {
  appId: process.env.VUE_APP_ALGOLIA_KEY,
  apiKey: process.env.VUE_APP_ALGOLIA_SECRET_KEY,
  useCookie: true,
});

export default {
  postDataAddToCart(params, product) {
    // if (process.env.NODE_ENV != "production") return false;

    // Dosi-in track
    Api().post("https://viewer.backend.dosi-in.vn/api/log/add-to-cart", params);
    // facebook track
    fbq("track", "AddToCart", {
      content_ids: [product.product_code],
      content_type: "product",
      value: product.price,
      currency: "VND",
    });

    // tiktok track
    ttq.track("AddToCart", {
      content_id: product.product_code,
      content_type: "product",
      content_name: product.product,
      content_category: product.category_name,
      price: product.price,
      value: product.price,
      quantity: params.amount,
      currency: "VND",
    });

    // google track
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.add",
      ecommerce: {
        add: {
          actionField: {
            list: "Shopping cart",
          },
          products: [
            {
              id: product.product_id,
              name: product.product,
              category: product.category_name,
              brand: product.company_name,
              quantity: params.amount,
            },
          ],
        },
      },
    });
    // algolia track
    aa('convertedObjectIDs', {
      userToken: store.state.account.status.loggedIn ? store.getters['account/userInfo'] : _getCookie('dsi'),
      index: 'dev_products',
      eventName: 'product - detail - addToCart',
      objectIDs: [product.product_id.toString()]
    });
  },
};
