<template>
  <div>
    <Teleport to="#modals">
      <div class="bs-modal-overlay modal" :class="{ open: show }">
        <div class="bs-modal-container">
          <div class="bs-modal-header">
            <div class="bs-modal_cancel" @click="closeMoDal">Đóng</div>
            <div class="bs-modal_title">Chọn sản phẩm</div>
          </div>
          <div class="bs-modal-body">
            <form id="dosiin_bs-submit">
              <div
                class="bs-modal-select-color center"
                v-if="state.existColor"
                :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
              >
                <dd class="bs-select-text text-grey-sm">Vuốt để chọn màu sắc</dd>
                <div class="color-list dosiin_color-list">
                  <Swiper
                    :slidesPerView="'auto'"
                    :spaceBetween="10"
                    :centeredSlides="true"
                  >
                    <SwiperSlide
                      class="swiper-slide"
                      v-for="(color, i) in colors"
                      :key="i"
                    >
                      <input
                        v-model="productData.color"
                        class="color-check-input"
                        type="radio"
                        :id="`color-${color.colorId}`"
                        :value="color.colorId"
                        :name="`productData[${product.product_id}][color]`"
                        @change="colorChange(color.colorId)"
                      />
                      <label
                        class="color-icon"
                        :for="`color-${color.colorId}`"
                        :style="{ 'background-color': color.code }"
                      ></label>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>

              <div
                class="bs-modal-select-size center"
                v-if="state.existSize"
                :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
              >
                <dd class="bs-select-text text-grey-sm center">Chọn kích thước</dd>
                <div class="size-list dosiin_size-list">
                  <Swiper
                    :slidesPerView="'auto'"
                    :spaceBetween="10"
                    :centeredSlides="true"
                  >
                    <SwiperSlide
                      class="swiper-slide size-item"
                      v-for="(size, i) in sizes"
                      :key="i"
                    >
                      <input
                        class="size-check-input dosiin_change-option dosiin_option_size"
                        v-model="productData.size"
                        :id="`size-${size.sizeId}`"
                        type="radio"
                        :value="size.sizeId"
                        :name="`productData[${product.product_id}][size]`"
                        @change="sizeChange(size.sizeId)"
                      />
                      <label class="size-icon" :for="`size-${size.sizeId}`">
                        <span class="text" v-text="size.sizeName"></span>
                      </label>
                    </SwiperSlide>
                  </Swiper>
                </div>

                <button
                  class="more-btn choice-size center d-flex align-items-center"
                  title="Xem thêm hướng dẫn chọn size"
                  @click.prevent="state.showSizeChart = true"
                  v-if="product.size_chart && product.size_chart.trim().length > 0"
                >
                  <i class="dsi dsi-act-rule dosiin_mr-2"></i>
                  <span class="text-pink-gradient">Bảng kích thước</span>
                </button>
              </div>

              <div
                class="bs-select-quantity"
                :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
              >
                <dd class="bs-select-text text-grey-sm">Số lượng sản phẩm</dd>
                <div
                  class="number-input-group dosiin_number-input-group qty_number-inner"
                >
                  <button
                    class="decrement-btn dosiin_decrement"
                    type="button"
                    @click="decrease"
                  >
                    <i class="dsi dsi-minus-2"></i>
                  </button>
                  <input
                    class="bs-quantity-input"
                    readonly
                    type="number"
                    :value="state.amount"
                    :name="`productData[${product.product_id}][amount]`"
                    :max="state.maxAmount ? state.maxAmount : state.totalAmount"
                    ref="productQty"
                  />
                  <button
                    class="increment-btn dosiin_increment"
                    type="button"
                    @click="increase"
                  >
                    <i class="dsi dsi-plus-2"></i>
                  </button>
                </div>
                <span
                  class="bs-select-text text-grey-sm"
                  v-if="state.maxAmount || state.totalAmount"
                >
                  {{
                    state.maxAmount || state.maxAmount === 0
                      ? state.maxAmount
                      : state.totalAmount
                  }}
                  sản phẩm có sẵn
                </span>
                <span v-if="state.showWarningAmount" class="text-pink-gradient">
                  Số lượng bạn chọn đã đạt mức tối đa của sản phẩm này
                </span>

                <span v-if="state.showWarningAddToCart" class="">
                  Vui lòng chọn Màu sắc, Kích thước, Số lượng của sản phẩm
                </span>
              </div>
              <div class="bs-preview-price dosiin_bs-preview-price">
                <dd class="bs-select-text text-grey-sm">Giá tạm tính</dd>
                <input
                  class="bs-quantity-input"
                  type="number"
                  id="bs-price-input"
                  name="price"
                />
                <dd
                  class="bs-price dosiin_bs-price"
                  v-text="currencyFormat(state.totalPrice)"
                ></dd>
              </div>

              <div class="bs-submit-wrapper">
                <button
                  class="btn-rounded dossin_btn-whitebg add-to-cart submit-buy dosiin_submit-buy dosiin_mr-2"
                  @click.prevent="addProductToCart"
                >
                  <div class="btn-rounded_child">
                    <span v-if="!state.loadingAddToCart" class="text"> Giỏ hàng</span>
                    <pulse-loader
                      v-else
                      :loading="state.loadingAddToCart"
                      color="#000000"
                    ></pulse-loader>
                  </div>
                </button>

                <button
                  class="primary-button button add-to-cart dosiin_submit-buy"
                  type="button"
                  @click.prevent="addProductToCart($event, true)"
                >
                  <span
                    v-if="!state.loadingBuyNow"
                    class="text"
                    v-text="state.maxAmount == 0 ? 'Hết hàng' : 'Mua ngay'"
                  ></span>
                  <pulse-loader
                    v-else
                    :loading="state.loadingBuyNow"
                    color="#000000"
                  ></pulse-loader>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Teleport>

    <Modal
      v-model="state.showSizeChart"
      @close="state.showSizeChart = false"
      :modalTypeClass="'modal-full-screen dosiin_viewsize'"
      :overlay="false"
    >
      <template v-slot:header>
        <h2 class="text-grey-lg">Thông số kích thước</h2>
        <div class="back-header-right-side"></div>
      </template>
      <template v-slot:body>
        <section class="modal-product-info">
          <div class="text-black-md" v-html="product.size_chart"></div>
        </section>
      </template>
    </Modal>

    <CartUpdateAddress
      v-model="state.showModalAddress"
      @update:modelVale="state.showModalAddress = false"
    />
  </div>
</template>

<script>
import Modal from "@/components/modals/Modal";
import CartUpdateAddress from "@/components/modals/CartUpdateAddress";
import cartService from "@/services/cart";
import trackingService from "@/services/tracking";

import { toRefs, ref, watch, inject, reactive, computed } from "vue";
import { _getSiblings } from "@/utlis/dom";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { _isValidAddress } from "@/utlis/cart";

export default {
  components: {
    Modal,
    CartUpdateAddress,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: {},
    },
  },

  setup(props, { emit }) {
    const toast = useToast();
    const store = useStore();
    const product = props.product;
    const show = ref(props.modalValue);
    const productQty = ref(null);

    const state = reactive({
      showModalAddress: false,
      showWarningAmount: false,
      showWarningAddToCart: false,
      canAddToCart: false,
      showSizeChart: false,
      totalAmount: parseInt(product.amount),
      amount: 1,
      maxAmount: parseInt(product.amount),
      totalPrice: parseInt(product.price),
      loadingAddToCart: false,
      loadingBuyNow: false,
      existColor: product.product_colors[0].colorId !== "0",
      existSize: product.product_sizes[0].sizeId !== "0",
    });

    const productData = reactive({
      product_id: product.product_id,
      amount: 1,
      color: null,
      size: null,
    });

    const sizes = product.product_sizes;
    const colors = product.product_colors;
    const colors_sizes = product.colors_sizes;
    const modalOpenCount = computed(() => store.getters["modal/countOpen"]);
    const currentUser = inject("currentUser");

    store.dispatch("modal/initModal");

    watch(
      () => props.modelValue,
      (isOpen) => {
        show.value = isOpen;

        if (show.value) {
          store.dispatch("modal/increase");
        } else {
          store.dispatch("modal/decrease");
        }
      }
    );

    watch(
      () => state.amount,
      () => {
        state.totalPrice = parseInt(product.price) * state.amount;
        productData.amount = state.amount;
      }
    );

    const closeMoDal = () => {
      show.value = !show.value;
      emit("update:modelValue", show.value);
    };

    const colorChange = (colorId) => {
      let color = colors_sizes.find((item) => item.colorId === colorId);
      // check color have size;
      if (color.productId && !state.existSize) {
        let sizesInColor = color.sizes;
        state.maxAmount = parseInt(sizesInColor[0].amount);
        productQty.value.setAttribute("max", state.maxAmount);
      }
      checkProductCanAddToCart();
    };

    const sizeChange = (sizeId) => {
      let sizes = colors_sizes[0].sizes;
      let size = sizes.find((item) => item.sizeId === sizeId);
      state.maxAmount = parseInt(size.amount);
      productQty.value.setAttribute("max", state.maxAmount);
      //reset amount
      state.amount = 1;
      //reset showWarningAmount
      state.showWarningAmount = false;
      checkProductCanAddToCart();
    };

    const decrease = () => {
      if (state.amount === 1) return false;
      state.showWarningAmount = false;
      state.amount = state.amount - 1;
    };

    const increase = () => {
      if (state.amount >= state.maxAmount) {
        state.showWarningAmount = true;
        return false;
      }
      state.showWarningAmount = false;
      state.amount = state.amount + 1;
    };

    async function addProductToCart($event, checkout = false) {
      if (!state.canAddToCart) {
        state.showWarningAddToCart = true;
        return;
      }

      if (checkout) state.loadingBuyNow = true;
      else state.loadingAddToCart = true;

      const response = await cartService.addProductToCart(productData);

      const trackingData = {
        uuid: localStorage.getItem("uuid"),
        userId: currentUser.value ? currentUser.value.user_id : 0,
        productId: product.product_id,
        categoryId: product.category_id,
        brandId: product.company_id,
        price: product.price,
        quantity: productData.amount,
        isCrawl: 0,
      };

      const resTracking = trackingService.postDataAddToCart(trackingData, product);

      if (response.data) {
        toast.success("Sản phẩm đã được thêm vào giỏ hàng");
        store.dispatch("cart/initCart", response.data);

        if (checkout) {
          window.location.href = "/checkout-checkout";
        }

        show.value = !show.value;
        emit("update:modelValue", show.value);
      }

      state.loadingAddToCart = false;
      state.loadingBuyNow = false;
      state.showWarningAddToCart = false;
    }

    async function checkProductCanAddToCart() {
      if (state.maxAmount === 0) {
        state.canAddToCart = false;
        state.showWarningAmount = false;
      } else if (state.existColor && state.existSize) {
        // check product exits color && exits size;
        if (productData.color && productData.size) state.canAddToCart = true;
        state.showWarningAddToCart = false;
      } else if (state.existColor) {
        // product exits color
        if (productData.color) state.canAddToCart = true;
        state.showWarningAddToCart = false;
      } else if (state.existSize) {
        // product exits size
        if (productData.size) state.canAddToCart = true;
        state.showWarningAddToCart = false;
      }
    }

    const onInitSwiper = (sw, type) => {
      //const count = sw.slides.length;
    };

    return {
      state,
      productData,
      show,
      sizes,
      colors,
      //ref
      productQty,
      // swiper event
      onInitSwiper,
      closeMoDal,
      decrease,
      increase,
      sizeChange,
      colorChange,
      // api methods
      addProductToCart,
      ...toRefs(props),
    };
  },
};
</script>

<style module>
.warningAddToCart {
  background-color: rgb(255 0 32 / 3%);
}
</style>
