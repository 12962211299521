<template>
  <Modal
    v-model="showModal"
    :modalTypeClass="'modal-full-screen dosiin_mypage-setting-address_list'"
    :overlay="false"
    @close="closeModalAddressPicker"
  >
    <template v-slot:header>
      <h2 class="text-grey-lg">Chọn địa chỉ</h2>
      <div class="back-header-right-side"></div>
    </template>

    <template v-slot:body>
      <div
        class="page-section mypage-setting my-page-section my-filter-tabs setting-address_list"
      >
        <Tabs
          wrapperClass="address-list_container"
          panelsWrapperClass=""
          navClass="btn-group mag-tabs"
          navItemClass="mag-tab"
          navItemLinkClass="text"
          navItemActiveClass="active"
          navItemDisabledClass="disable"
          @clicked="clickedTab"
          @changed="tabChanged"
          :options="{
            useUrlFragment: false,
            defaultTabHash: 'cities',
          }"
          cache-lifetime="0"
          ref="tabs"
        >
          <Tab name="Tỉnh/Thành phố" id="cities">
            <section class="city-container">
              <ul class="group-wrapper filter-categories">
                <li
                  class="filter-item cm-load-state"
                  v-for="(city, i) in state.cities"
                  :key="i"
                >
                  <label class="filter-label" :for="`city_${i}`">
                    <input
                      :id="`city_${i}`"
                      v-model="address.state"
                      class="checkbox-input"
                      type="radio"
                      name="filterCity"
                      :value="city.code"
                    />
                    <span class="filter-label-text">{{ city.state }}</span>
                    <i class="check-icon"></i>
                  </label>
                </li>
              </ul>
            </section>
          </Tab>

          <Tab name="Quận/Huyện" id="districts" :is-disabled="!state.cities.length">
            <section class="districts-container">
              <ul class="group-wrapper filter-categories">
                <li
                  class="filter-item cm-load-state"
                  v-for="(district, i) in state.districts"
                  :key="i"
                >
                  <label class="filter-label" :for="`district_${i}`">
                    <input
                      :id="`district_${i}`"
                      v-model="address.b_maqh"
                      class="checkbox-input"
                      type="radio"
                      name="filterDistrict"
                      :value="district.maqh"
                    />
                    <span class="filter-label-text">{{ district.name }}</span>
                    <i class="check-icon"></i>
                  </label>
                </li>
              </ul>
            </section>
          </Tab>

          <Tab name="Phường/Xã" id="wards" :isDisabled="!state.districts.length">
            <section class="wards-container">
              <ul class="group-wrapper filter-categories">
                <li
                  class="filter-item cm-load-state"
                  v-for="(ward, i) in state.wards"
                  :key="i"
                >
                  <label class="filter-label" :for="`ward_${i}`">
                    <input
                      :id="`ward_${i}`"
                      v-model="address.b_xaid"
                      class="checkbox-input"
                      type="radio"
                      name="filterXaid"
                      :value="ward.xaid"
                    />
                    <span class="filter-label-text">{{ ward.name }}</span>
                    <i class="check-icon"></i>
                  </label>
                </li>
              </ul>
            </section>
          </Tab>
        </Tabs>
      </div>
    </template>

    <template v-slot:footer>
      <button
        class="primary-button button"
        type="button"
        @click="pickAddress"
        :disabled="!address.state || !address.b_maqh || !address.b_xaid"
      >
        <span v-if="!state.loading" class="primary-link_text">Chọn</span>
        <pulse-loader v-else :loading="state.loading" color="#000000"></pulse-loader>
      </button>
    </template>
  </Modal>
</template>

<script>
import { reactive, watch, ref } from "vue";
import provinceService from "@/services/province";
import Modal from "@/components/modals/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "updateUserAddress"],
  setup(props, { emit }) {
    const showModal = ref(false);
    const tabs = ref(null);
    const state = reactive({
      cities: [],
      districts: [],
      wards: [],
      loading: false,
    });

    watch(
      () => props.modelValue,
      (isOpen) => {
        showModal.value = isOpen;
      }
    );

    const address = reactive({
      state: null,
      b_maqh: null,
      b_xaid: null,
    });

    getCities();

    watch(
      () => address.state,
      () => {
        getDistricts();
      }
    );

    watch(
      () => address.b_maqh,
      () => {
        getWards();
      }
    );

    watch(
      () => address.b_xaid,
      () => {}
    );

    async function getCities() {
      const response = await provinceService.fetchCities();

      if (response.data) {
        state.cities = response.data;
      }
    }

    async function getDistricts() {
      state.loading = true;
      const response = await provinceService.fetchDistricts(address.state);
      if (response.data) {
        state.districts = response.data;
        tabs.value.selectTab("#districts");
      }

      state.loading = false;
    }

    async function getWards() {
      state.loading = true;
      const response = await provinceService.fetchWards(address.b_maqh);
      state.wards = response.data;
      tabs.value.selectTab("#wards");
      state.loading = false;
    }

    const closeModalAddressPicker = () => {
      showModal.value = !showModal.value;
      emit("update:modelValue", showModal.value);
    };

    const pickAddress = () => {
      const addressPicked = {
        city: null,
        district: null,
        ward: null,
      };

      addressPicked.city = state.cities.find((city) => city.code === address.state);
      addressPicked.district = state.districts.find(
        (district) => district.maqh === address.b_maqh
      );
      addressPicked.ward = state.wards.find((ward) => ward.xaid === address.b_xaid);

      emit("updateUserAddress", addressPicked);

      closeModalAddressPicker();
    };

    const clickedTab = (tab) => {};

    const tabChanged = (selectedTab) => {
      // console.log(selectedTab);
    };

    return {
      state,
      showModal,
      address,
      tabs,
      pickAddress,
      clickedTab,
      tabChanged,
      closeModalAddressPicker,
    };
  },
};
</script>
