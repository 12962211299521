<template>
  <div :id="`product_${product.product_id}`">
    <a
      v-for="(image, index) in rebuildImageProduct()"
      :key="index"
      :href="image.largeURL"
      :data-pswp-width="1000"
      :data-pswp-height="1000"
      target="_blank"
      rel="noreferrer"
    >
      <img class="uploaded-img" :src="image.largeURL" />
    </a>
  </div>
</template>
<script>
import PhotoSwipeLightbox from "@/plugins/photoswipe/photoswipe-lightbox.esm.js";
import "@/plugins/photoswipe/photoswipe.css";
import PhotoSwipe from "@/plugins/photoswipe/photoswipe.esm.js";
import { onMounted } from "vue";
import { _productThumbnail } from "@/utlis/thumbnail";
export default {
  props: {
    product: {
      type: Object,
    },
  },
  setup(props) {
    var lightbox = null;
    onMounted(() => {
      lightbox = new PhotoSwipeLightbox({
        gallery: "#product_" + props.product.product_id,
        children: "a",
        pswpModule: () => PhotoSwipe,
      });

      lightbox.init();
    });

    const rebuildImageProduct = () => {
      var images = props.product.image_pairs;
      images = Object.values(images);
      let imgObjects = [];
      for (let i = 0; i < images.length; i++) {
        imgObjects.push({
          largeURL: _productThumbnail(images[i], true),
          title: props.product.product,
        });
      }
      return imgObjects;
    };

    const openGallery = (index) => {
      lightbox.loadAndOpen(index);
    };

    return {
      rebuildImageProduct,
      openGallery,
    };
  },
};
</script>
