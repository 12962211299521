<template>
  <div class="product-detail-page">
    <BackHeader>
      <template v-slot:headerIcon>
        <LikeButton
          v-if="product"
          likeable_type="product"
          :likeable_id="product.product_id"
          :isLiked="product.is_like"
          objectType="product"
          type="text"
        />

        <a
          class="more-menu-wrapper-btn dosiin_more-menu-wrapper action-btn"
          @click="showMenuDropdown"
          style="margin-right: 15px"
        >
          <i class="dsi dsi-more-h"></i>
        </a>
      </template>
    </BackHeader>

    <LoadingComponent v-if="!product" />

    <div v-else>
      <div class="product-detail-slider-section dosiin_product-detail-slider-custom">
        <div class="dosiin_bg-overlay"></div>
        <div class="dosiin_bg-image" style="backgo"></div>
        <div class="dosiin_product-detail-slider swiper-container product-detail-slider">
          <Swiper
            :loop="swiperWithSwipePhoto.loop"
            :loopAdditionalSlides="swiperWithSwipePhoto.loopAdditionalSlides"
            :slidesPerView="swiperWithSwipePhoto.slidesPerView"
            :watchSlidesVisibility="swiperWithSwipePhoto.watchSlidesVisibility"
            :watchSlidesProgress="swiperWithSwipePhoto.watchSlidesProgress"
            :pagination="swiperWithSwipePhoto.pagination"
            :modules="modules"
            @init="onSwiper"
            @slideChangeTransitionEnd="onSlideChange"
            :id="`product_${product.product_id}`"
          >
            <SwiperSlide>
              <a
                :href="
                  _productThumbnailSticker(
                    product.main_pair,
                    product.sticker.main_pair,
                    true
                  )
                "
                :data-pswp-width="1000"
                :data-pswp-height="1000"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  v-lazy="
                    _productThumbnailSticker(
                      product.main_pair,
                      product.sticker.main_pair,
                      true
                    )
                  "
                  :data-bg="_productThumbnail(product.main_pair)"
                  :alt="product.product"
                  width="335"
                  height="400"
                />
              </a>
            </SwiperSlide>
            <SwiperSlide v-for="(image, index) in product.image_pairs" :key="index">
              <a
                :href="_productThumbnail(image, true)"
                :data-pswp-width="670"
                :data-pswp-height="670"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  v-lazy="_productThumbnail(image, true)"
                  :data-bg="_productThumbnail(image)"
                  width="335"
                  height="400"
                  :alt="product.product"
                />
              </a>
            </SwiperSlide>
          </Swiper>
          <div class="dosiin_slider-controls">
            <div class="dosiin_slider-pagination swipable-slider_pagination"></div>
          </div>
        </div>

        <div class="product-properties">
          <dl
            class="item"
            v-if="product.product_colors && product.product_colors[0].colorId > 0"
          >
            <dt class="prop-name">
              <b v-text="product.product_colors.length"></b>&nbsp;Màu sắc
            </dt>
            <dd class="list">
              <label
                class="product-color-item"
                v-for="(color, i) in product.product_colors"
                :key="i"
                :style="'background-color : ' + color.code"
              ></label>
            </dd>
          </dl>

          <dl
            class="item"
            v-if="product.product_sizes && product.product_sizes[0].sizeId > 0"
          >
            <dt class="prop-name">
              <b v-text="product.product_sizes.length"></b>&nbsp;kích thước
            </dt>
            <dd class="list">
              <label
                class="product-size-item"
                v-for="(size, i) in product.product_sizes"
                :key="i"
                v-text="size.sizeName"
              ></label>
            </dd>
          </dl>
        </div>
      </div>

      <div class="dosiin_animation-content animation-content">
        <div class="page-section product-detail-description-section">
          <div class="product-description">
            <div class="text-dark-md name">
              <h1 class="text-dark-md name" v-text="product.product"></h1>
            </div>
            <dd class="price-group">
              <span class="inline-bl price" v-text="currencyFormat(product.price)"></span>
              <span
                class="inline-bl origin-price"
                v-if="discountPrc(product) > 0"
                v-text="currencyFormat(product.list_price)"
              ></span>
              <span class="inline-bl sale-discount" v-if="discountPrc(product) > 0"
                >{{ discountPrc(product) }}%</span
              >
            </dd>
            <dd class="product-review-info" v-if="state.discussion">
              <star-rating
                :rating="
                  state.discussion.average_rating
                    ? parseInt(state.discussion.average_rating)
                    : 0
                "
                :star-size="12"
                :read-only="true"
                :show-rating="true"
                text-class="product-tem_social-text"
                active-color="#FFCC07"
                :star-points="[
                  23,
                  2,
                  14,
                  17,
                  0,
                  19,
                  10,
                  34,
                  7,
                  50,
                  23,
                  43,
                  38,
                  50,
                  36,
                  34,
                  46,
                  19,
                  31,
                  17,
                ]"
              />

              <i class="icon-dot-separate">•</i>
              <div class="flex-inline-bl">
                <i class="icon-heart-filled"></i>
                <span class="text-bold data-count dosiin_likeable-count">{{
                  product.like_count
                }}</span>
              </div>
              <template v-if="product.sold > 0">
                <i class="icon-dot-separate">•</i>
                <data class="text-bold data-count sold-data"
                  >Đã bán {{ product.sold }}</data
                >
              </template>
            </dd>
          </div>
          <div class="product-coupons text-black-md" v-if="state.promotions.length != 0">
            <span class="dosiin_promo-modal-btn store-dosiin">
              Mã Giảm Giá<span>+{{ state.promotions.length }}</span>
            </span>
            <ul class="coupons dosiin_ml-auto" v-if="state.promotionsValidated">
              <li
                class="dosiin_promo-modal-btn"
                v-for="(promotion, index) in state.promotionsValidated"
                :key="index"
              >
                <div class="flex-inline-bl coupon-tag-sm">
                  <div class="circle-shape left-circle"></div>
                  <div class="left-content"></div>
                  <div class="vertical-bor"></div>
                  <div class="right-content">
                    <data class="text-pink-gradient coupon-name">{{
                      promotion.short_name
                    }}</data>
                  </div>
                  <div class="circle-shape right-circle"></div>
                </div>
              </li>
            </ul>
            <button class="dosiin_ml-2" @click="showPromotions = true">
              <i class="dsi dsi-act-arrow-forward"></i>
            </button>
          </div>
        </div>

        <div class="page-section padding_0" v-if="state.brand.company_id">
          <div class="brand-shop_wrapper brand-item">
            <div class="brand-description_wrapper">
              <div class="brand-description_img brand-item_img-wrapper">
                <BrandLogo :brand="state.brand" />
              </div>
              <div class="brand-description_info">
                <router-link
                  :to="_brandUrl(state.brand.seo_name)"
                  :title="product.company_name"
                >
                  <h5 class="brand-item_title" v-text="state.brand.company"></h5>
                </router-link>
                <dl class="brand-item_social">
                  <dd>{{ state.brand.products_count }} sản phẩm</dd>
                </dl>
              </div>
              <div class="brand-description_follow">
                <FollowButton
                  @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                  class="follow-with-text-btn"
                  followable_type="brand"
                  :followable_id="state.brand.company_id"
                  :is_follow="state.brand.is_follow"
                />
              </div>
            </div>
            <div class="brand-shop_social-info">
              <div class="grid grid-33">
                <div class="grid-item">
                  <a href="#">
                    <article class="brand-shop-social_item">
                      <h6>{{ ratingFormat(state.brand.discussion.average_rating) }}/5</h6>
                      <p>
                        <i class="dsi dsi-s16 dsi-star"></i>
                        <span>Đánh giá</span>
                      </p>
                    </article>
                  </a>
                </div>
                <div class="grid-item">
                  <article class="brand-shop-social_item">
                    <h6 v-text="numberFormat(state.brand.follow_count)"></h6>
                    <p>
                      <i class="dsi dsi-s16 dsi-love"></i>
                      <span>Theo dõi</span>
                    </p>
                  </article>
                </div>
                <div class="grid-item">
                  <article class="brand-shop-social_item">
                    <h6
                      v-text="
                        _momentFromNow(state.brand.timestamp).str_replace('trước', '')
                      "
                    ></h6>
                    <p>
                      <i class="dsi dsi-s16 dsi-clock"></i>
                      <span>Tham gia</span>
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
          <div
            class="page-section related_slider_wrapper product_details_slider product_details_slider-width overflow"
            v-if="state.posts.length"
          >
            <div class="header-section">
              <h2
                class="header-section-left-side section-title d-flex align-items-center"
              >
                Style liên quan
              </h2>
              <div class="dosiin_slider-controls">
                <div
                  class="dosiin_related_panigation swipable-slider_pagination"
                  id="indicators"
                ></div>
              </div>
            </div>
            <div class="dosiin_position-relative" v-if="state.posts.length">
              <div class="dosiin_related_slide">
                <Swiper
                  :modules="modules"
                  :slidesPerView="2.1"
                  :spaceBetween="8"
                  :slidesPerGroup="2"
                  :pagination="paginationPostStyle"
                  class="relate-slide-wrapper overflow-unset"
                >
                  <SwiperSlide v-for="(post, i) in state.posts" :key="i">
                    <div class="related_image">
                      <router-link
                        :to="_vnfdUrl(post.seo_name)"
                      >
                        <img
                          v-lazy="{
                            src: _postImage(post.main_pair),
                            loading: _styleDefault,
                            error: _styleDefault,
                          }"
                          class="dosiin_w-100"
                          width="670"
                          height="670"
                        />
                      </router-link>
                      <div class="related_icon">
                        <Attach :post="post" />
                      </div>
                    </div>

                    <Tags class="text-pink-gradient related_tag" :post="post" />
                    <div class="">
                      <a
                        class="related_brand-info"
                        v-if="post.brand_id && post.brand_id != '0'"
                        :href="
                          '/?dispatch=companies.view&company_id=' +
                          post.brand_id +
                          '&tab=style'
                        "
                      >
                        <img
                          width="36"
                          height="36"
                          v-lazy="{
                            src: _companyAvatar(post.company_logo.theme),
                            loading: _userDefaultAvatar,
                            error: _userDefaultAvatar,
                          }"
                        />
                        {{ post.company_name }}
                      </a>
                      <router-link
                        v-else
                        class="related_brand-info"
                        :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                      >
                        <img
                          width="36"
                          height="36"
                          v-lazy="{
                            src: _userAvatar(post.avatar),
                            loading: _userDefaultAvatar,
                            error: _userDefaultAvatar,
                          }"
                        />
                        <p class="truncate">{{ post.user_name }}</p>
                      </router-link>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <ProductsSliderHorizon
            wrapperClass="page-section overflow"
            title="Sản phẩm khác"
            gridClass="grid-33"
            :products="state.products_in_brand"
            :options="{
              slidesPerView: 2.5,
              slidesPerGroup: 2,
              spaceBetween: 9,
            }"
          />
        </div>

        <div class="page-section product-datail-product-infomation-section">
          <h2 class="section-title">Thông tin sản phẩm</h2>
          <div class="attribute-list">
            <div class="attribute-item" v-if="product.category_id">
              <div class="attribute-name">Danh mục</div>
              <div class="attribute-value" v-text="product.category_name"></div>
            </div>

            <div class="attribute-item" v-if="product.company_name">
              <div class="attribute-name">Thương hiệu</div>
              <div class="attribute-value" v-text="product.company_name"></div>
            </div>

            <div class="attribute-item" v-if="product.product_code">
              <div class="attribute-name">SKU</div>
              <div class="attribute-value" v-text="product.product_code"></div>
            </div>

            <template v-if="product.short_description_origin">
              <div
                class="attribute-item"
                v-for="(key, i) in Object.keys(product.short_description_origin)"
                :key="i"
              >
                <div class="attribute-name">{{ key }}</div>
                <div class="attribute-value">
                  {{ product.short_description_origin[key] }}
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="page-section product-detail-product-description-section">
          <h2 class="section-title">Mô tả sản phẩm</h2>
          <template
            v-if="product.full_description && product.full_description.trim().length > 0"
          >
            <div
              class="dosiin_description-truncate description-content short-content"
              ref="fullDescription"
              v-html="product.full_description"
            ></div>
            <div class="truncate-btn" :class="!expand ? 'expand-btn' : ''">
              <span
                class="text-pink-gradient dosiin_truncate-btn"
                @click="expandContent"
                v-text="expand ? 'Thu gọn' : 'Xem thêm nội dung'"
              ></span>
            </div>
          </template>
          <div
            class="dosiin_text-center"
            v-else
            style="
              height: 200px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <img :src="_emptyDescription" />
            <p class="">Chưa có mô tả dành cho sản phẩm này</p>
          </div>
        </div>

        <div class="page-section customer-review-section" v-if="state.discussion">
          <h2 class="section-title">Khách hàng đánh giá</h2>
          <div class="rating-product">
            <star-rating
              :rating="
                state.discussion.average_rating
                  ? parseInt(state.discussion.average_rating)
                  : 0
              "
              :star-size="12"
              :read-only="true"
              :show-rating="true"
              text-class="product-tem_social-text"
              active-color="#FFCC07"
              :star-points="[
                23,
                2,
                14,
                17,
                0,
                19,
                10,
                34,
                7,
                50,
                23,
                43,
                38,
                50,
                36,
                34,
                46,
                19,
                31,
                17,
              ]"
            />
            <span class="dosiin_ml-2" v-if="state.discussion.search"
              >({{ state.discussion.search.total_items }} lượt đánh giá)</span
            >
          </div>

          <ul
            class="dosiin_product-review-list"
            v-if="state.discussion.posts && state.discussion.posts.length === 0"
          >
            <li class="review-item">
              <div class="dosiin_text-center">
                <img :src="_emptyReview" />
                <p>Hãy là người đầu tiên đánh giá sản phẩm này</p>
              </div>
            </li>
          </ul>

          <DiscussionsList v-else :discussions="state.discussion.posts" />

          <div class="seemore-btn">
            <a
              class="primary-button"
              :href="`/index.php?dispatch=products.view_discussion&amp;product_id=${product.product_id}`"
              title="Xem và đánh giá"
            >
              <span class="primary-link_text">Xem và đánh giá</span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </a>
          </div>
        </div>

        <ProductsSliderHorizon
          wrapperClass="page-section overflow"
          title="Sản phẩm tương tự"
          gridClass="grid-33"
          :products="state.products_similar"
          :options="{
            slidesPerView: 2.5,
            slidesPerGroup: 2,
            spaceBetween: 9,
          }"
        />

        <div class="page-section">
          <h2 class="section-title">Bạn cũng có thể thích</h2>
          <ProductLink
            :query="{
              product_viewed: true,
              limit: 20,
              exclude_pid: product.product_id,
            }"
            gridClass="grid-50"
            :hidePagination="true"
          />
        </div>
      </div>
    </div>
    <footer class="footer-offset footer product-detail-footer" v-if="product">
      <div class="footer_container container-fluid">
        <button
          v-if="product.verify_status == 'A'"
          class="primary-button button"
          @click.prevent="showVariants = true"
        >
          <i class="dsi dsi-act-addbasket"></i>
          <span class="primary-link_text">Chọn mua</span>
        </button>

        <template v-else>
          <template v-if="product.verify_status == 'D' && product.crawler_url.length">
            <button
              v-if="product.amount == 0"
              class="primary-button button"
              type="button"
            >
              <span class="primary-link_text">Tạm Hết Hàng</span>
            </button>

            <div class="dosiin_text-center dosiin_w-100">
              <!-- class="dosiin_login primary-link_text dosiin_add-to-cart" -->
              <div class="btn-rounded dossin_btn-whitebg dosiin_mb-1">
                <div class="btn-rounded_child">
                  <a
                    class="dosiin_add-to-cart"
                    :href="product.crawler_url"
                    target="_blank"
                    rel="nofollow"
                    style="display: flex; justify-content: center; align-items: center"
                    @click="trackingCrawler"
                  >
                    <span class="text-pink-gradient dosiin_mr-2">
                      Đến trang mua hàng
                    </span>
                    <i class="dsi dsi-act-arrow-forward"></i>
                  </a>
                </div>
              </div>
              <span class="note-add-to-cart"
                >MẶT HÀNG KHÔNG BÁN TRỰC TIẾP BỞI DOSIIN</span
              >
            </div>
          </template>

          <button v-else class="primary-button button" type="button" disabled>
            <span class="primary-link_text">Tạm thời ngưng đặt hàng</span>
          </button>
        </template>
      </div>
    </footer>

    <PromoModal
      v-model="showPromotions"
      :promotions="state.promotions"
      buttonType="use"
    />

    <ProductVariant v-if="product" v-model="showVariants" :product="product" />

    <!-- Menu Dropdown -->
    <Teleport to="#modals">
      <div
        class="more-menu-overlay modal"
        id="dosiin_more-menu"
        :class="menuDropdown ? 'open' : ''"
      >
        <div
          class="modal-more-menu"
          id="dosiin_more-menu-container"
          v-click-outside="closeMenuDropdown"
        >
          <DropdownMobile />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import BackHeader from "@/components/common/BackHeader";
import ProductItem from "@/components/product/ProductItem";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import PromoModal from "@/components/modals/PromotionsMB";
import ProductVariant from "@/components/modals/ProductVariant";
import ProductLink from "@/components/product/ProductLink";
import StarRating from "vue-star-rating";
import DiscussionsList from "@/components/common/DiscussionsList";
import Attach from "@/components/post/Attach";
import Tags from "@/components/post/Tags";
// import VuePictureSwipe from 'vue3-picture-swipe';
import vClickOutside from "click-outside-vue3";
import trackingService from "@/services/tracking";

import { inject, ref, watch } from "@vue/runtime-core";
import { Pagination } from "swiper";
import { _productThumbnail } from "@/utlis/thumbnail";

import PhotoSwipeLightbox from "@/plugins/photoswipe/photoswipe-lightbox.esm.js";
import "@/plugins/photoswipe/photoswipe.css";
import PhotoSwipe from "@/plugins/photoswipe/photoswipe.esm.js";
import DropdownMobile from "@/components/menus/DropdownMobile";

export default {
  components: {
    Attach,
    Tags,
    BackHeader,
    StarRating,
    ProductItem,
    PromoModal,
    ProductLink,
    ProductVariant,
    DiscussionsList,
    // VuePictureSwipe,
    ProductsSliderHorizon,
    DropdownMobile,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const state = inject("state");
    const product = inject("product");
    const fullDescription = ref(null);
    const showPromotions = ref(false);
    const showVariants = ref(false);
    const expand = ref(false);
    const swiperWithSwipePhoto = {
      loop: true,
      loopAdditionalSlides: 1,
      slidesPerView: 1,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swipable-slider_pagination",
        clickable: true,
        renderBullet: (index, className) =>
          `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
      },
      on: {
        init() {
          setBackgroundImageSlide(".dosiin_product-detail-slider", ".dosiin_bg-image");
        },
        slideChangeTransitionEnd() {
          setBackgroundImageSlide(".dosiin_product-detail-slider", ".dosiin_bg-image");
        },
      },
    };

    const paginationPostStyle = {
      el: ".dosiin_related_panigation",
      clickable: true,
      renderBullet: (index, className) =>
        `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
    };

    const menuDropdown = ref(false);
    const currentUser = inject("currentUser");
    function setBackgroundImageSlide(eleSlide, eleSet) {
      let imgSrc = document.querySelector(eleSlide + " .swiper-slide-active img");
      const targetEle = document.querySelector(eleSet);
      if (targetEle) {
        targetEle.style.backgroundImage = "url(" + imgSrc.getAttribute("data-bg") + ")";
      }
    }

    watch(
      () => product.value,
      () => {
        setTimeout(() => {
          const lightbox = new PhotoSwipeLightbox({
            gallery: "#product_" + product.value.product_id,
            children: "a",
            initialZoomLevel: "fit",
            secondaryZoomLevel: 1.5,
            maxZoomLevel: 1,
            pswpModule: () => PhotoSwipe,
          });

          lightbox.init();
        }, 500);
      }
    );

    const onSwiper = (swiper) => {
      setBackgroundImageSlide(".dosiin_product-detail-slider", ".dosiin_bg-image");
    };

    const onSlideChange = () => {
      setBackgroundImageSlide(".dosiin_product-detail-slider", ".dosiin_bg-image");
    };

    const expandContent = () => {
      expand.value = !expand.value;
      fullDescription.value.classList.toggle("short-content");
    };

    const showMenuDropdown = () => {
      menuDropdown.value = !menuDropdown.value;
    };

    const closeMenuDropdown = () => {
      menuDropdown.value = false;
    };

    const trackingCrawler = () => {
      const trackingData = {
        uuid: localStorage.getItem("uuid"),
        userId: currentUser.value ? currentUser.value.user_id : 0,
        productId: product.value.product_id,
        categoryId: product.value.category_id,
        brandId: product.value.company_id,
        price: product.value.price,
        quantity: 1,
        isCrawl: 1,
      };

      trackingService.postDataAddToCart(trackingData, product.value);
    };

    return {
      product,
      state,
      showPromotions,
      showVariants,
      modules: [Pagination],
      //refs
      fullDescription,
      //medthods
      expand,
      expandContent,
      swiperWithSwipePhoto,
      onSwiper,
      onSlideChange,
      menuDropdown,
      showMenuDropdown,
      closeMenuDropdown,
      trackingCrawler,
      paginationPostStyle,
    };
  },
};
</script>
