// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProductVariant_warningAddToCart_2WfR3{background-color:rgba(255,0,32,.03)}", ""]);
// Exports
exports.locals = {
	"warningAddToCart": "ProductVariant_warningAddToCart_2WfR3"
};
module.exports = exports;
