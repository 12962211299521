<template>
  <Modal
    v-model="state.showModal"
    :modalTypeClass="'modal-full-screen modal-user-info'"
    :overlay="false"
    @close="closeModalUpdateAddress"
  >
    <template v-slot:header>
      <h2 class="text-grey-lg">Thông tin nhận hàng</h2>
      <div class="back-header-right-side">
        <a class="support" href="https://m.me/dosiinvietnam">Hỗ trợ </a>
      </div>
    </template>

    <template v-slot:body>
      <div class="form-user-apply">
        <h3>Thành viên Dosiin</h3>
        <p>Nhận nhiều ưu đãi khi là thành viên của Dosiin</p>
        <div class="link-group d-flex">
          <router-link
            :to="state.currentUrlRegister"
            class="btn-rounded btn-resigter dossin_btn-whitebg"
          >
            <div class="btn-rounded_child">
              <span class="text-pink-gradient">Đăng ký</span>
            </div>
          </router-link>

          <router-link :to="state.currentUrl" class="primary-button button">
            <span class="primary-link_text">Đăng nhập</span>
          </router-link>
        </div>
      </div>

      <AddressForm v-model="userData" @update:modelValue="addressTyping" />
    </template>

    <template v-slot:footer>
      <button
        class="primary-button button"
        type="button"
        :disabled="updateButton"
        @click.prevent="updateAddressToCart"
      >
        <span v-if="!state.loading" class="primary-link_text">Cập nhật địa chỉ</span>
        <pulse-loader v-else :loading="state.loading" color="#000000"></pulse-loader>
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/modals/Modal";
import AddressForm from "@/components/forms/AddressForm";
import { reactive, ref, watch } from "vue";
import { _isValidAddress } from "@/utlis/cart";
import cartService from "@/services/cart";

export default {
  components: {
    Modal,
    AddressForm,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    productData: {
      type: Object,
      default: {},
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
      showModal: false,
      currentUrl:
        "/login?" + encodeURIComponent(window.location.origin + window.location.pathname),
      currentUrlRegister:
        "/register?" +
        encodeURIComponent(window.location.origin + window.location.pathname),
    });

    const updateButton = ref(false);

    watch(
      () => props.modelValue,
      (isOpen) => {
        state.showModal = isOpen;
      }
    );

    const userData = ref({
      b_firstname: null,
      email: null,
      b_phone: null,
      b_address: null,
      b_state: 0,
      b_maqh: 0,
      b_xaid: 0,
    });

    const updateAddressToCart = async () => {
      state.loading = true;

      const response = await cartService.addUserDataToCart(
        userData.value,
        props.productData
      );

      if (response && response.data) {
        window.location.href = "checkout-checkout";
      }

      state.loading = false;
    };

    const closeModalUpdateAddress = () => {
      state.showModal = !state.showModal;
      emit("update:modelValue", state.showModal);
    };

    const addressTyping = (userTyping) => {
      userData.value = userTyping;
    };

    return {
      state,
      userData,
      updateButton,
      closeModalUpdateAddress,
      addressTyping,
      updateAddressToCart,
    };
  },
};
</script>

<style>
.help-inline {
  color: red;
  /* width: 71%; */
  position: absolute;
  right: 10px;
  top: 11%;
  font-size: 12px;
}
</style>
