<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <LoadingComponent v-if="product === null" />
    <div v-else>
      <div class="product_detail-main-page-inner">
        <div class="product_detail-slider">
          <div class="swiper-container-detail gallery-thumbs">
            <Swiper
              @swiper="setThumbsSwiper"
              :style="'height : 100%'"
              :modules="[FreeMode, Navigation, Thumbs]"
              :direction="'vertical'"
              :spaceBetween="8"
              :slidesPerView="7"
              :allowTouchMove="true"
              :watchOverflow="true"
              :freeMode="true"
              :navigation="{
                nextEl: '.dosiin_swiper-vertical-position-next',
                prevEl: '.dosiin_swiper-vertical-position-prev',
              }"
              watch-slides-progress
            >
              <SwiperSlide>
                <img
                  width="60"
                  height="80"
                  v-lazy="
                    _productThumbnailSticker(
                      product.main_pair,
                      product.sticker.main_pair,
                      true
                    )
                  "
                  :alt="product.product"
                />
              </SwiperSlide>

              <SwiperSlide v-for="(image, index) in product.image_pairs" :key="index">
                <img
                  width="60"
                  height="80"
                  v-lazy="_productThumbnail(image, true)"
                  :alt="product.product"
                />
              </SwiperSlide>

              <div
                class="swiper-button-next dosiin_swiper-button dosiin_swiper-vertical dosiin_swiper-vertical-position-next"
              ></div>
              <div
                class="swiper-button-prev dosiin_swiper-button dosiin_swiper-vertical dosiin_swiper-vertical-position-prev"
              ></div>
            </Swiper>
          </div>
          <div class="swiper-container-detail gallery-top">
            <Swiper
              :spaceBetween="10"
              :slidesPerView="1"
              :modules="[Navigation, Thumbs]"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :thumbs="{ swiper: thumbsSwiper }"
              :loop="true"
              :style="'height : 100%'"
              :id="`product_${product.product_id}`"
            >
              <SwiperSlide>
                <a
                  :href="
                    _productThumbnailSticker(
                      product.main_pair,
                      product.sticker.main_pair,
                      true
                    )
                  "
                  :data-pswp-width="1000"
                  :data-pswp-height="1000"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div class="dsi-product-zoom">
                    <i class="dsi dsi-zoom"></i>
                  </div>
                  <img
                    v-lazy="
                      _productThumbnailSticker(
                        product.main_pair,
                        product.sticker.main_pair,
                        true
                      )
                    "
                    :alt="product.product"
                  />
                </a>
              </SwiperSlide>

              <SwiperSlide
                v-for="(image, index) in Object.values(product.image_pairs)"
                :key="index"
              >
                <a
                  :href="_productThumbnail(image, true)"
                  :data-pswp-width="1000"
                  :data-pswp-height="1000"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div class="dsi-product-zoom">
                    <i class="dsi dsi-zoom"></i>
                  </div>
                  <img v-lazy="_productThumbnail(image, true)" :alt="product.product" />
                </a>
              </SwiperSlide>
              <div class="swiper-button-next dosiin_swiper-button-gradient"></div>
              <div class="swiper-button-prev dosiin_swiper-button-gradient"></div>
            </Swiper>
          </div>
        </div>

        <div class="product_detail-body">
          <section class="product-detail-description-section">
            <dl class="product-description">
              <dt class="text-dark-md name details_name"></dt>
              <div class="top-product-name">
                <span class="name-brand">
                  Thương hiệu:
                  <router-link
                    :to="_brandUrl(state.brand.seo_name)"
                    :title="product.company_name"
                  >
                    <span class="text-pink-gradient" v-text="product.company_name"></span>
                  </router-link>
                </span>
                <template
                  v-if="
                    currentUser &&
                    (currentUser.user_type == 'A' || currentUser.user_type == 'V')
                  "
                >
                  <span class="id-product"
                    >ID:
                    <span class="text-pink-gradient">
                      <a
                        :href="`https://admin.dosi-in.com/dosiin_adn.php?dispatch=products.update&product_id=${product.product_id}`"
                        :title="product.product"
                        v-text="product.product_id"
                        style="color: #fe252c"
                        v-if="currentUser.user_type == 'A'"
                        target="_blank"
                      >
                      </a>
                      <button v-else type="text" v-text="product.product_id"></button>
                    </span>
                  </span>
                </template>

                <dt class="text-dark-md name details_name">
                  <p v-text="product.product"></p>
                  <LikeButton
                    v-if="product"
                    likeable_type="product"
                    :likeable_id="product.product_id"
                    :isLiked="product.is_like"
                    objectType="product"
                    type="text"
                    @likeObject="() => (product.like_count = product.like_count + 1)"
                    @unlikeObject="() => (product.like_count = product.like_count - 1)"
                  />
                </dt>
              </div>

              <div class="line"></div>
              <dd class="price-group product_price-details">
                <data class="inline-bl price">{{ currencyFormat(product.price) }}</data>
                <data
                  class="inline-bl origin-price"
                  v-if="discountPrc(product) > 0"
                  v-text="currencyFormat(product.list_price)"
                ></data>
                <data class="inline-bl sale-discount" v-if="discountPrc(product) > 0"
                  >{{ discountPrc(product) }}%</data
                >
              </dd>
              <dd class="product-review-info product_review-box" v-if="state.discussion">
                <star-rating
                  :rating="
                    state.discussion && state.discussion.average_rating
                      ? parseInt(state.discussion.average_rating)
                      : 0
                  "
                  :star-size="16"
                  :read-only="true"
                  :show-rating="true"
                  text-class="product-tem_social-text"
                  active-color="#FFCC07"
                  :star-points="[
                    23,
                    2,
                    14,
                    17,
                    0,
                    19,
                    10,
                    34,
                    7,
                    50,
                    23,
                    43,
                    38,
                    50,
                    36,
                    34,
                    46,
                    19,
                    31,
                    17,
                  ]"
                />

                <i class="icon-dot-separate">•</i>
                <div class="flex-inline-bl dosiin_mr-1">
                  <i class="icon-heart-filled"></i>
                  <data class="text-bold data-count" v-text="product.like_count"></data>
                </div>
                <template v-if="product.sold > 0">
                  <i class="icon-dot-separate">•</i>
                  <data class="text-bold data-count sold-data"
                    >Đã bán {{ product.sold }}</data
                  >
                </template>
              </dd>
              <div
                class="line"
                v-if="
                  product.product_colors.length && product.product_colors[0].colorId > 0
                "
              ></div>
              <div
                class="product_details-properties"
                :class="[childState.showWarningAddToCart ? $style.warningAddToCart : '']"
                v-if="
                  product.product_colors.length && product.product_colors[0].colorId > 0
                "
              >
                <div
                  class="ttl d-flex align-items-center justify-content-between dosiin_mb-2"
                >
                  <div class="color_details-ttl">
                    <h5>Màu sắc</h5>
                    <span>{{ product.product_colors.length }} màu</span>
                  </div>
                </div>
                <div class="bs-modal-select-color center dosiin_mt-2">
                  <div class="color-list">
                    <div
                      class="color-list-inner"
                      style="
                        transition-duration: 0ms;
                        transform: translate3d(0px, 0px, 0px);
                      "
                    >
                      <div
                        class="select_properties_item"
                        v-for="(color, i) in product.product_colors"
                        :key="i"
                      >
                        <input
                          v-model="productData.color"
                          :id="`color-${color.colorId}`"
                          class="color-check-input"
                          type="radio"
                          :name="`productData[${product.product_id}][color]`"
                          :value="color.colorId"
                          @change="colorChange(color.colorId)"
                        />
                        <label
                          class="color-icon"
                          :for="`color-${color.colorId}`"
                          :style="{
                            'background-color': color.code,
                          }"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="line"
                v-if="product.product_sizes.length && product.product_sizes[0].sizeId > 0"
              ></div>
              <div
                class="product_details-properties scroll-box-modal-product"
                :class="[childState.showWarningAddToCart ? $style.warningAddToCart : '']"
                v-if="product.product_sizes.length && product.product_sizes[0].sizeId > 0"
              >
                <div
                  class="ttl d-flex align-items-center justify-content-between dosiin_mb-2"
                >
                  <div class="color_details-ttl">
                    <h5>Kích thước</h5>
                    <span>{{ product.product_sizes.length }} Kích thước</span>
                  </div>
                  <button
                    class="table_size"
                    @click="childState.showSizeChart = true"
                    v-if="product.size_chart && product.size_chart.trim().length > 0"
                  >
                    <i class="dsi dsi-act-rule"></i>
                    <span class="dosiin_ml-2">Bảng kích cỡ </span>
                  </button>
                </div>
                <div class="bs-modal-select-size">
                  <div class="size-list scroll-box-modal">
                    <div
                      class="size-list-inner"
                      aria-live="polite"
                      style="
                        transition-duration: 0ms;
                        transform: translate3d(0px, 0px, 0px);
                      "
                    >
                      <div
                        class="select_properties_item"
                        v-for="(size, index) in product.product_sizes"
                        :key="index"
                      >
                        <input
                          class="size-check-input"
                          v-model="productData.size"
                          :id="`size-${size.sizeId}`"
                          type="radio"
                          :value="size.sizeId"
                          :name="`productData[${product.product_id}][size]`"
                          @change="sizeChange(size.sizeId)"
                        />
                        <label class="size-icon" :for="`size-${size.sizeId}`">
                          <span class="text" v-text="size.sizeName"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div
                class="product_details-properties"
                :class="[childState.showWarningAddToCart ? $style.warningAddToCart : '']"
              >
                <div
                  class="qty_ttl"
                  v-if="childState.maxAmount || childState.totalAmount"
                >
                  <p>
                    {{
                      childState.maxAmount || childState.maxAmount === 0
                        ? childState.maxAmount
                        : childState.totalAmount
                    }}
                    sản phẩm có sẵn
                  </p>
                </div>
                <div
                  class="number-input-group dosiin_number-input-group qty_number-inner dosiin_mt-2"
                >
                  <button class="decrement-btn dosiin_decrement" @click="decrease">
                    <i class="dsi dsi-minus-2"></i>
                  </button>
                  <input
                    readonly
                    class="bs-quantity-input"
                    ref="productQty"
                    type="number"
                    :value="childState.amount"
                    :name="`productData[${product.product_id}][amount]`"
                    :max="
                      childState.maxAmount ? childState.maxAmount : childState.totalAmount
                    "
                    min="0"
                  />
                  <button class="increment-btn dosiin_increment" @click="increase">
                    <i class="dsi dsi-plus-2"></i>
                  </button>
                </div>
                <span
                  class="text-pink-gradient dosiin_mt-2 dosiin_d-block"
                  v-if="childState.showWarningAmount"
                >
                  Số lượng bạn chọn đã đạt mức tối đa của sản phẩm này
                </span>
                <span
                  class="dosiin_mt-2 dosiin_d-block"
                  v-if="childState.showWarningAddToCart"
                  style="color: #fe252c"
                >
                  Vui lòng chọn Màu sắc, Kích thước, Số lượng của sản phẩm
                </span>
              </div>
              <template v-if="state.promotions.length != 0">
                <div class="line"></div>
                <div class="product_details-properties">
                  <div class="product-coupons text-black-md details_coupons">
                    <data
                      ><b class="dosiin_mr-2">Mã khuyến mãi</b
                      ><span>+{{ state.promotions.length }}</span></data
                    >
                    <div class="list_coupons">
                      <ul class="coupons" v-if="state.promotionsValidated">
                        <li
                          v-for="(promotion, index) in state.promotionsValidated"
                          :key="index"
                        >
                          <div class="flex-inline-bl coupon-tag-sm">
                            <div class="circle-shape left-circle"></div>
                            <div class="left-content"></div>
                            <div class="vertical-bor"></div>
                            <div class="right-content">
                              <data class="text-pink-gradient coupon-name">{{
                                promotion.short_name
                              }}</data>
                            </div>
                            <div class="circle-shape right-circle"></div>
                          </div>
                        </li>
                      </ul>
                      <button
                        class="dosiin_ml-2 open-promotion-btn dosiin_promo-modal-btn dosiin_mr-2"
                        @click="childState.showPromotions = true"
                      >
                        <i class="dsi dsi-arrow-forward"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </template>

              <div
                class="bs-submit-wrapper bt_submit"
                v-if="product.verify_status === 'A'"
              >
                <template v-if="product.amount == 0">
                  <button
                    class="btn-rounded submit-buy dosiin_submit-buy dosiin_mr-2"
                    :disabled="true"
                  >
                    <span>Tạm hết hàng</span>
                  </button>
                </template>

                <template v-else>
                  <button
                    class="btn-rounded dossin_btn-whitebg submit-buy dosiin_submit-buy dosiin_mr-2"
                    @click.prevent="addProductToCart"
                  >
                    <div class="btn-rounded_child">
                      <span v-if="!childState.loadingAddToCart" class="text">
                        Giỏ hàng</span
                      >
                      <pulse-loader
                        v-else
                        :loading="childState.loadingAddToCart"
                        color="#000000"
                      ></pulse-loader>
                    </div>
                  </button>
                  <button
                    class="primary-button button add-to-cart"
                    @click.prevent="addProductToCart($event, true)"
                  >
                    <span
                      v-if="!childState.loadingBuyNow"
                      class="text"
                      v-text="childState.maxAmount == 0 ? 'Hết hàng' : 'Mua ngay'"
                    ></span>
                    <pulse-loader
                      v-else
                      :loading="childState.loadingBuyNow"
                      color="#000000"
                    ></pulse-loader>
                  </button>
                </template>
              </div>

              <template
                v-else-if="product.verify_status == 'D' && product.crawler_url.length"
              >
                <div class="line"></div>
                <div class="product_details-properties">
                  <div class="go_store btn-rounded dossin_btn-whitebg">
                    <div class="btn-rounded_child">
                      <div class="info_product-details primary-button">
                        <a
                          class="primary-link_text d-flex align-items-center justify-content-center dosiin_w-100"
                          style="margin: 0 !important"
                          :href="product.crawler_url"
                          target="_blank"
                          rel="nofollow"
                          @click="trackingCrawler"
                        >
                          <span class="text-pink-gradient"> Đến trang mua hàng </span>
                          <i class="dosiin_ml-2 dsi dsi-act-arrow-forward"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <p>SẢN PHẨM KHÔNG BÁN TRỰC TIẾP TRÊN DOSIIN</p>
                </div>
              </template>

              <div class="bs-submit-wrapper bt_submit" v-else>
                <button class="primary-button button add-to-cart" :disabled="true">
                  <span class="primary-link_text">Tạm thời ngưng đặt hàng</span>
                </button>
              </div>

              <div class="line"></div>
              <div class="product_details-properties">
                <div class="info_product-details">
                  <div
                    class="details-product"
                    :style="
                      product.verify_status != 'A' || product.verify_status != 'P'
                        ? 'background: rgba(16, 16, 16, 0.05);'
                        : ''
                    "
                  >
                    <img
                      class="dosiin_mr-2"
                      src="https://dosi-in.com/images/assets/icons/icon-maketplace.png"
                      alt="Dosi-in"
                      v-if="
                        product.verify_status === 'A' || product.verify_status === 'P'
                      "
                    />
                    <img
                      class="dosiin_mr-2"
                      src="https://dosi-in.com/images/assets/icons/icon-not-marketplace.png"
                      alt="Dosi-in"
                      v-else-if="
                        product.verify_status == 'D' && product.crawler_url.length
                      "
                    />
                    <div class="details-product-txt">
                      <h5
                        v-if="
                          product.verify_status === 'A' || product.verify_status === 'P'
                        "
                      >
                        Sản phẩm được bán chính hãng tại Dosiin
                      </h5>
                      <h5
                        v-else-if="
                          product.verify_status == 'D' && product.crawler_url.length
                        "
                      >
                        Sản phẩm không được phân phối chính hãng tại Dosiin
                      </h5>
                      <div class="icon-show-dropdow">
                        <p>Tìm hiểu chính sách</p>
                        <Popper :class="$style.dark" arrow hover>
                          <button type="button">
                            <i
                              class="dsi dsi-info dsi-s16"
                              style="filter: opacity(0.7)"
                            ></i>
                          </button>

                          <template #content>
                            <div style="width: 207px">
                              <template
                                v-if="
                                  product.verify_status === 'A' ||
                                  product.verify_status === 'P'
                                "
                              >
                                <b>Nhà cung cấp:</b> Các thương hiệu đang bán hàng trực
                                tiếp trên Dosi-in.com. Các chính sách khuyến mãi, thanh
                                toán và đổi trả sẽ được áp dùng theo chính sách của
                                Dosiin.
                              </template>
                              <template
                                v-else-if="
                                  product.verify_status == 'D' &&
                                  product.crawler_url.length
                                "
                              >
                                <b>Tiếp thị liên kết:</b>
                                Các thương hiệu đang hợp tác quảng cáo với Dosiin. Người
                                dùng sau khi nhấn “Đặt hàng ngay” trên Dosi-in.com sẽ được
                                chuyển đến trang web của thương hiệu để hoàn tất việc mua
                                hàng. Các chính sách khuyến mãi, thanh toán và đổi trả sẽ
                                được áp dụng theo chính sách của thương hiệu đó.
                              </template>
                            </div>
                          </template>
                        </Popper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dl>
          </section>
        </div>
      </div>

      <div class="product_page-details-wrapper dosiin_mt-16">
        <div class="product_page-details-left">
          <div class="brand-shop_wrapper brand-item box_details">
            <div
              class="brand-description_wrapper box_details-width"
              v-if="state.brand.company_id"
            >
              <div class="p_product-dtl">
                <div class="brand-description_img brand-item_img-wrapper dosiin_mr-2">
                  <BrandLogo :brand="state.brand" />
                </div>
                <div class="brand-description_info">
                  <router-link
                    :to="_brandUrl(state.brand.seo_name)"
                    :title="product.company_name"
                  >
                    <h5 class="brand-item_title" v-text="state.brand.company"></h5>
                  </router-link>
                  <dl class="brand-item_social">
                    <dd>
                      <b>{{ state.brand.follow_count }}</b>
                      <span class="normal">người theo dõi</span>
                    </dd>
                    <dd>
                      <b>{{ state.brand.products_count }}</b>
                      <span class="normal">sản phẩm</span>
                    </dd>
                  </dl>
                </div>
              </div>
              <div class="brand-description_follow">
                <FollowButton
                  @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                  class="follow-with-text-btn"
                  followable_type="brand"
                  :followable_id="state.brand.company_id"
                  :is_follow="state.brand.is_follow"
                />
              </div>
            </div>
            <div
              class="brand-shop_social-info box_details-width"
              v-if="state.brand.discussion"
            >
              <div class="grid box_details-item">
                <div class="grid-item">
                  <div class="brand-shop-social_item box_details-txt">
                    <h6>{{ ratingFormat(state.brand.discussion.average_rating) }}/5</h6>
                    <p>
                      <i class="dsi dsi-s16 dsi-star"></i>
                      <span class="dosiin_ml-1">Đánh giá</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="grid-item box_details-item">
                <article class="brand-shop-social_item box_details-txt">
                  <h6 v-text="numberFormat(state.brand.like_count)"></h6>
                  <p>
                    <i class="dsi dsi-s16 dsi-love"></i>
                    <span class="dosiin_ml-1">Lượt thích</span>
                  </p>
                </article>
              </div>
              <div class="grid-item box_details-item">
                <article class="brand-shop-social_item box_details-txt">
                  <h6
                    v-text="
                      _momentFromNow(state.brand.timestamp).str_replace('trước', '')
                    "
                  ></h6>
                  <p>
                    <i class="dsi dsi-s16 dsi-clock"></i>
                    <span class="dosiin_ml-1">Tham gia</span>
                  </p>
                </article>
              </div>
            </div>
          </div>
          <section class="info_details dosiin_mt-16">
            <div class="info_details_inner">
              <div class="info_ttl">
                <h3>Thông tin sản phẩm</h3>
              </div>
              <div class="info_details-table">
                <div class="info_details-item">
                  <div class="info-item-wrapper">
                    <h4>Danh mục</h4>
                  </div>
                  <div class="info-item-desc">
                    <p v-text="product.category_name"></p>
                  </div>
                </div>

                <div class="info_details-item" v-if="product.company_name">
                  <div class="info-item-wrapper">
                    <h4>Thương hiệu</h4>
                  </div>
                  <div class="info-item-desc">
                    <p v-text="product.company_name"></p>
                  </div>
                </div>

                <div class="info_details-item" v-if="product.product_code">
                  <div class="info-item-wrapper">
                    <h4>SKU</h4>
                  </div>
                  <div class="info-item-desc">
                    <p v-text="product.product_code"></p>
                  </div>
                </div>

                <template v-if="product.short_description_origin">
                  <div
                    class="info_details-item"
                    v-for="(key, i) in Object.keys(product.short_description_origin)"
                    :key="i"
                  >
                    <div class="info-item-wrapper">
                      <h4>{{ key }}</h4>
                    </div>
                    <div class="info-item-desc">
                      <p>
                        {{ product.short_description_origin[key] }}
                      </p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
          <div
            class="page-section product-detail-product-description-section dosiin_mt-16 p_details-inner"
          >
            <h2>Mô tả sản phẩm</h2>
            <template
              v-if="
                product.full_description && product.full_description.trim().length > 0
              "
            >
              <div
                class="dosiin_description-truncate description-content short-content"
                ref="refFullDescription"
                v-html="product.full_description"
              ></div>
              <div
                class="dosiin_truncate-btn truncate-btn"
                :class="childState.expand == false ? '' : ' expand-btn'"
                ref="refBtnExpand"
                v-if="showBtnExpand"
                @click="expandDescription"
              >
                <span
                  class="text-pink-gradient dosiin_mr-2"
                  v-text="childState.expand ? 'Thu gọn' : 'Xem thêm nội dung'"
                ></span>
                <i
                  :class="[
                    'dsi',
                    childState.expand == false
                      ? 'dsi-act-fill-rankingdown'
                      : 'dsi-act-fill-rankingup',
                  ]"
                ></i>
              </div>
            </template>
            <div
              class="dosiin_text-center"
              v-else
              style="
                height: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
            >
              <img :src="_emptyDescription" />
              <p class="">Chưa có mô tả dành cho sản phẩm này</p>
            </div>
          </div>

          <ProductDiscussion
            v-if="state.discussion"
            :object="product"
            :discussion="state.discussion"
          />
        </div>
        <div class="product_page-details-right" style="height: fit-content">
          <div class="grid-33-product-swipable-slider product_bg-grid">
            <div class="header-section">
              <h2
                class="header-section-left-side section-title d-flex align-items-center"
              >
                Sản phẩm khác
              </h2>
            </div>
            <div class="product_grid" v-if="state.products_in_brand">
              <div
                class="grid-item"
                v-for="(product, index) in state.products_in_brand.slice(0, 3)"
                :key="index"
              >
                <div class="product-item">
                  <ProductItem :product="product" />
                </div>
              </div>
            </div>
            <router-link
              class="btn-rounded dossin_btn-whitebg dosiin_mt-16 btn_see-more dosiin_d-block"
              :to="_brandUrl(state.brand.seo_name)"
            >
              <div class="btn-rounded_child">
                <span class="text dosiin_mr-2">Xem thêm</span>
                <i class="dsi dsi-act-drop-down"></i>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div
        class="related_slider_wrapper product_details_slider product_details_slider-width"
        v-if="state.posts.length"
      >
        <div class="header-section">
          <h2 class="header-section-left-side section-title d-flex align-items-center">
            Style liên quan
          </h2>
          <div class="dosiin_slider-controls">
            <div
              class="dosiin_related_panigation swipable-slider_pagination"
              id="indicators"
            ></div>
          </div>
        </div>
        <div class="dosiin_position-relative" v-if="state.posts.length">
          <div class="dosiin_related_slide">
            <Swiper
              :modules="[Navigation, Pagination]"
              :slidesPerView="4"
              :spaceBetween="16"
              :slidesPerGroup="4"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :pagination="paginationPostStyle"
              class="relate-slide-wrapper dosiin_position-unset"
            >
              <SwiperSlide v-for="(post, i) in state.posts" :key="i">
                <div class="related_image" @click="showDetailPost(post)">
                  <!-- <router-link :to="{ name: 'detail', query: { vnfd_id: post.vnfd_id } }"> -->
                  <img
                    v-lazy="{
                      src: _postImage(post.main_pair),
                      loading: _styleDefault,
                      error: _styleDefault,
                    }"
                    class="dosiin_w-100"
                    width="670"
                    height="670"
                  />
                  <!-- </router-link> -->
                  <div class="related_icon">
                    <Attach :post="post" />
                  </div>
                </div>

                <Tags class="text-pink-gradient related_tag" :post="post" />
                <div class="">
                  <a
                    class="related_brand-info"
                    v-if="post.brand_id && post.brand_id != '0'"
                    :href="
                      '/?dispatch=companies.view&company_id=' +
                      post.brand_id +
                      '&tab=style'
                    "
                  >
                    <img
                      width="36"
                      height="36"
                      v-lazy="{
                        src: _companyAvatar(post.company_logo.theme),
                        loading: _userDefaultAvatar,
                        error: _userDefaultAvatar,
                      }"
                    />
                    {{ post.company_name }}
                  </a>
                  <router-link
                    v-else
                    class="related_brand-info"
                    :to="{ name: 'profile', query: { profile_id: post.user_id } }"
                  >
                    <img
                      width="36"
                      height="36"
                      v-lazy="{
                        src: _userAvatar(post.avatar),
                        loading: _userDefaultAvatar,
                        error: _userDefaultAvatar,
                      }"
                    />
                    <p class="truncate">{{ post.user_name }}</p>
                  </router-link>
                </div>
              </SwiperSlide>
              <div
                class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
              ></div>
              <div
                class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
              ></div>
            </Swiper>
          </div>
        </div>
      </div>
      <ProductsSliderHorizon
        wrapperClass="product_details_slider product_details_slider-width dosiin_mt-16"
        title="Sản phẩm tương tự"
        gridClass="grid-20"
        :products="state.products_similar"
        :options="{
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 16,
        }"
      />

      <div class="product_details_slider product_details_slider-width">
        <div class="header-section">
          <h2 class="header-section-left-side section-title d-flex align-items-center">
            Bạn cũng có thể thích
          </h2>
          <div class="header-section-right-side">
            <router-link
              :to="{ name: 'products' }"
              class="primary-button"
              title="Xem thêm"
            >
              <span class="primary-link_text">Xem thêm</span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
          </div>
        </div>

        <ProductLink
          :query="{
            product_viewed: true,
            limit: 20,
            exclude_pid: product.product_id,
          }"
          gridClass="grid-20"
          :hidePagination="true"
        />
      </div>

      <div class="p_sticky-wrapper d-none">
        <div class="p_ticky-inner item-product-checkout">
          <a class="d-flex align-items-center" :title="product.product">
            <img
              class="dosiin_mr-2"
              v-lazy="
                _productThumbnailSticker(product.main_pair, product.sticker.main_pair)
              "
              :alt="product.product"
              width="44"
              height="44"
            />
            <span v-text="product.product"></span>
          </a>

          <div
            class="size-group d-inline-flex"
            v-if="productData.color || productData.size"
          >
            <span
              v-if="productData.color"
              class="color"
              :style="`background: ${getColorText(productData.color)}`"
            ></span>
            <span v-if="productData.color && productData.size" class="line"></span>
            <span
              v-if="productData.size"
              class="size"
              v-text="getSizeText(productData.size)"
            ></span>
          </div>

          <div class="price">
            {{ currencyFormat(product.price) }}
          </div>

          <div class="number-input-group dosiin_number-input-group qty_number-inner">
            <button class="decrement-btn dosiin_decrement" @click="decrease">
              <i class="dsi dsi-minus-2 dsi-s24"></i>
            </button>
            <input
              class="bs-quantity-input"
              ref="productQty"
              type="number"
              min="0"
              :value="childState.amount"
              :max="childState.maxAmount ? childState.maxAmount : childState.totalAmount"
              :name="`productData[${product.product_id}][amount]`"
            />
            <button class="increment-btn dosiin_increment" @click="increase">
              <i class="dsi dsi-plus-2 dsi-s24"></i>
            </button>
          </div>

          <div class="price">{{ currencyFormat(childState.totalPrice) }}</div>
          <div class="bs-submit-wrapper bt_submit bt_submit-unset">
            <template v-if="product.verify_status === 'A'">
              <template v-if="product.amount == 0">
                <button
                  class="btn-rounded submit-buy dosiin_submit-buy dosiin_mr-2"
                  :disabled="true"
                >
                  <span>Tạm hết hàng</span>
                </button>
              </template>
              <template v-else>
                <!-- :disabled="!childState.canAddToCart" -->
                <button
                  class="btn-rounded dossin_btn-whitebg submit-buy dosiin_submit-buy dosiin_mr-2"
                  @click.prevent="addProductToCart"
                >
                  <div class="btn-rounded_child">
                    <span
                      v-if="!childState.loadingAddToCart"
                      class="text"
                      :class="{ text: childState.canAddToCart }"
                    >
                      Giỏ hàng</span
                    >
                    <pulse-loader
                      v-else
                      :loading="state.loadingAddToCart"
                      color="#000000"
                    ></pulse-loader>
                  </div>
                </button>
                <!-- :disabled="!childState.canAddToCart" -->
                <button
                  class="primary-button button add-to-cart dosiin_ml-2"
                  @click.prevent="addProductToCart($event, true)"
                >
                  <span
                    v-if="!childState.loadingBuyNow"
                    class="text"
                    :class="{ text: childState.canAddToCart }"
                    >Mua ngay</span
                  >
                  <pulse-loader
                    v-else
                    :loading="childState.loadingBuyNow"
                    color="#000000"
                  ></pulse-loader>
                </button>
              </template>
            </template>
            <div
              class="product_details-properties"
              v-else-if="product.verify_status == 'D' && product.crawler_url.length"
            >
              <div class="info_product-details primary-button">
                <div class="go_store btn-rounded dossin_btn-whitebg">
                  <div class="btn-rounded_child">
                    <a
                      class="primary-link_text d-flex align-items-center"
                      style="margin: 0 !important"
                      :href="product.crawler_url"
                      target="_blank"
                      rel="nofollow"
                      @click="trackingCrawler"
                    >
                      <div class="text-pink-gradient">Đến trang mua hàng</div>
                      <i class="dosiin_ml-2 dsi dsi-act-arrow-forward"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="bs-submit-wrapper bt_submit bt_submit-unsett" v-else>
              <button class="primary-button button add-to-cart" :disabled="true">
                <span class="primary-link_text">Tạm thời ngưng đặt hàng</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal2
        v-model="childState.showSizeChart"
        @close="childState.showSizeChart = false"
        :modalTypeClass="'modal-medium'"
        :overlay="false"
      >
        <template v-slot:header>
          <h3>Thông tin kích thước sản phẩm</h3>
        </template>
        <template v-slot:body>
          <div class="modal-product-info" v-html="product.size_chart"></div>
        </template>
      </Modal2>

      <PromoModalPC
        v-model="childState.showPromotions"
        :promotions="state.promotions"
        buttonType="use"
      />

      <ProductGallery v-show="false" ref="gallery" v-if="product" :product="product" />

      <PostDetailModal ref="modalPostDetail" v-model="showDetailModal" />
    </div>
  </div>
</template>

<script>
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import ProductItem from "@/components/product/ProductItem";
import PromoModalPC from "@/components/modals/PromotionsPC";
import StarRating from "vue-star-rating";
import ProductLink from "@/components/product/ProductLink";
import ProductDiscussion from "@/components/product/ProductDiscussionPc";
import ProductAlgolia from "@/algolia/product/ProductAlgolia";
import ProductGallery from "@/components/images/ProductGallery";
import Modal from "@/components/modals/Modal";
import Modal2 from "@/components/modals/Modal2";
import Popper from "vue3-popper";
import PhotoSwipeLightbox from "@/plugins/photoswipe/photoswipe-lightbox.esm.js";
import "@/plugins/photoswipe/photoswipe.css";
import PhotoSwipe from "@/plugins/photoswipe/photoswipe.esm.js";
import Tags from "@/components/post/Tags";
import cartService from "@/services/cart";
import trackingService from "@/services/tracking";
import Attach from "@/components/post/Attach";
import PostDetailModal from "@/components/modals/DetailPostStyle";

import { inject, ref, watch, reactive } from "vue";
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper";

import { useStore } from "vuex";
import { useToast } from "vue-toastification";

export default {
  components: {
    Tags,
    Modal,
    Modal2,
    Popper,
    Attach,
    ProductItem,
    PromoModalPC,
    StarRating,
    ProductLink,
    ProductDiscussion,
    ProductAlgolia,
    ProductsSliderHorizon,
    ProductGallery,
    PostDetailModal,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const state = inject("state");
    const product = inject("product");
    // refs
    const refFullDescription = ref(null);
    const showBtnExpand = ref(true);
    const productQty = ref(null);
    const gallery = ref(null);
    const childState = reactive({
      showSizeChart: false,
      showPromotions: false,
      showDiscussionForm: false,
      totalAmount: 0, //parseInt(product.amount),
      amount: 1,
      maxAmount: 0, //parseInt(product.amount),
      totalPrice: 0, //parseInt(product.price),
      loadingAddToCart: false,
      loadingBuyNow: false,
      showWarningAmount: false,
      showWarningAddToCart: false,
      canAddToCart: false,
      existColor: false,
      existSize: false,
      expand: false,
    });
    const paginationPostStyle = {
      el: ".dosiin_related_panigation",
      clickable: true,
      renderBullet: (index, className) =>
        `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
    };
    const currentUser = inject("currentUser");
    const showDetailModal = ref(false);
    const modalPostDetail = ref(false);

    const productData = reactive({
      product_id: null,
      amount: 1,
      color: null,
      size: null,
    });

    watch(
      () => product.value,
      () => {
        if (!product.value) return;
        // sizes = product.value.product_sizes;
        // colors = product.value.product_colors;
        // colors_sizes = product.value.colors_sizes;

        childState.totalAmount = parseInt(product.value.amount);
        childState.maxAmount = parseInt(product.value.amount);
        childState.totalPrice = parseInt(product.value.price);
        childState.existColor = product.value.product_colors.length
          ? product.value.product_colors[0].colorId !== "0"
          : false;
        childState.existSize = product.value.product_sizes.length
          ? product.value.product_sizes[0].sizeId !== "0"
          : false;
        productData.product_id = product.value.product_id;

        setTimeout(() => {
          const addCartBar = document.querySelector(".p_sticky-wrapper");
          const heighImage = document.querySelector(".product_detail-slider");

          if (addCartBar) {
            window.addEventListener("scroll", function () {
              const { scrollTop } = document.documentElement;
              if (
                scrollTop > heighImage.clientHeight &&
                addCartBar.classList.contains("d-none")
              ) {
                addCartBar.classList.remove("d-none");
              } else if (
                scrollTop < heighImage.clientHeight &&
                !addCartBar.classList.contains("d-none")
              ) {
                addCartBar.classList.add("d-none");
              }
            });
          }

          const lightbox = new PhotoSwipeLightbox({
            gallery: "#product_" + product.value.product_id,
            children: "a",
            pswpModule: () => PhotoSwipe,
          });

          lightbox.init();
        }, 500);
      }
    );

    // swiper
    let thumbsSwiper = ref(null);

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    const expandDescription = () => {
      childState.expand = !childState.expand;
      refFullDescription.value.classList.toggle("short-content");
    };

    watch(
      () => childState.amount,
      () => {
        childState.totalPrice = parseInt(product.value.price) * childState.amount;
        productData.amount = childState.amount;
      }
    );

    const sizeChange = (sizeId) => {
      let sizes = product.value.colors_sizes[0].sizes;
      let size = sizes.find((item) => item.sizeId === sizeId);

      childState.maxAmount = parseInt(size.amount);
      productQty.value.setAttribute("max", state.maxAmount);
      //reset amount
      childState.amount = 1;
      //reset showWarningAmount
      childState.showWarningAmount = false;
      checkProductCanAddToCart();
    };

    const colorChange = (colorId) => {
      let color = product.value.colors_sizes.find((item) => item.colorId === colorId);
      // check color have size;
      if (color.productId && !childState.existSize) {
        let sizesInColor = color.sizes;
        childState.maxAmount = parseInt(sizesInColor[0].amount);
        productQty.value.setAttribute("max", childState.maxAmount);
      }

      checkProductCanAddToCart();
    };

    async function checkProductCanAddToCart() {
      if (childState.maxAmount === 0) {
        childState.canAddToCart = false;
        childState.showWarningAmount = false;
      } else if (childState.existColor && childState.existSize) {
        // check product exits color && exits size;
        if (productData.color && productData.size) childState.canAddToCart = true;
        childState.showWarningAddToCart = false;
      } else if (childState.existColor) {
        // product exits color
        if (productData.color) childState.canAddToCart = true;
        childState.showWarningAddToCart = false;
      } else if (childState.existSize) {
        // product exits size
        if (productData.size) childState.canAddToCart = true;
        childState.showWarningAddToCart = false;
      }
    }

    const decrease = () => {
      if (childState.amount === 1) return false;
      childState.showWarningAmount = false;
      childState.amount = childState.amount - 1;
    };

    const increase = () => {
      if (childState.amount >= childState.maxAmount) {
        childState.showWarningAmount = true;
        return false;
      }
      childState.showWarningAmount = false;
      childState.amount = childState.amount + 1;
    };

    async function addProductToCart($event, checkout = false) {
      if (!childState.canAddToCart) {
        childState.showWarningAddToCart = true;
        return;
      }

      if (checkout) childState.loadingBuyNow = true;
      else childState.loadingAddToCart = true;

      const response = await cartService.addProductToCart(productData);
      // tracking
      const trackingData = {
        uuid: localStorage.getItem("uuid"),
        userId: currentUser.value ? currentUser.value.user_id : 0,
        productId: product.value.product_id,
        categoryId: product.value.category_id,
        brandId: product.value.company_id,
        price: product.value.price,
        quantity: productData.amount,
        isCrawl: 0,
      };

      const resTracking = trackingService.postDataAddToCart(trackingData, product.value);

      if (response.data) {
        toast.success("Sản phẩm đã được thêm vào giỏ hàng");
        store.dispatch("cart/initCart", response.data);

        if (checkout) {
          window.location.href = "/checkout-checkout";
        }
      }

      childState.loadingAddToCart = false;
      childState.loadingBuyNow = false;
      childState.showWarningAddToCart = false;
    }

    const getColorText = (colorId) => {
      let colors = product.value.product_colors;
      let color = colors.find((item) => item.colorId == colorId);
      return color.code;
    };

    const getSizeText = (sizeId) => {
      let sizes = product.value.product_sizes;
      let size = sizes.find((item) => item.sizeId == sizeId);
      return size.sizeName;
    };

    const openGallery = (index) => {
      gallery.value.openGallery(index);
    };

    const trackingCrawler = () => {
      const trackingData = {
        uuid: localStorage.getItem("uuid"),
        userId: currentUser.value ? currentUser.value.user_id : 0,
        productId: product.value.product_id,
        categoryId: product.value.category_id,
        brandId: product.value.company_id,
        price: product.value.price,
        quantity: productData.amount,
        isCrawl: 1,
      };

      trackingService.postDataAddToCart(trackingData, product.value);
    };

    function showDetailPost(post) {
      modalPostDetail.value.detailPost(post);
    }

    return {
      product,
      state,
      childState,
      productData,
      showBtnExpand,
      childState,
      // refs
      productQty,
      gallery,
      refFullDescription,
      // swipers
      thumbsSwiper,
      setThumbsSwiper,
      FreeMode,
      Navigation,
      Pagination,
      Thumbs,
      openGallery,
      // methods
      getColorText,
      getSizeText,
      sizeChange,
      colorChange,
      decrease,
      increase,
      expandDescription,
      // api methods.
      trackingCrawler,
      addProductToCart,
      currentUser,
      paginationPostStyle,
      modalPostDetail,
      showDetailPost,
      showDetailModal,
    };
  },
};
</script>

<style module>
.dark {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: white;
  --popper-theme-border-width: 0px;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 16px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  font-size: 12px;
}

.warningAddToCart {
  background-color: rgb(255 0 32 / 3%);
}
</style>

<style scoped>
:deep(.popper) {
  line-height: 150%;
}

.info_product-details .details-product-txt .icon-show-dropdow {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
