
import { _dsEmptyObject } from '@/utlis/helpers';

export function _checkPromotionsCanBeApplyWithProduct(promotions , product){
    let promos = [];

    if (promotions && product.product_id) {
        for( var i = 0 ; i < promotions.length ; i++){
            let promotion = promotions[i]
            let conditions = promotion.conditions.conditions;
            for( const key in conditions ){
                let condition = conditions[key];

                if(
                    (condition.condition == 'subtotal' && parseFloat(product.price) >= condition.value)
                    || (condition.condition == 'price' && parseFloat(product.price) >= condition.value)
                ){
                    promos.push(promotion);
                }
            }
        }
    }

    return promos;

}