<template>
  <div :key="state.productId">
    <ProductMB v-if="layout == 4" />
    <ProductPC v-else />
  </div>
</template>

<script>
import ProductMB from "@/pages/mb/Product";
import ProductPC from "@/pages/pc/Product";
import { provide, reactive, ref } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import ProductService from "@/services/product";
import BrandService from "@/services/brand";
import DiscussionService from "@/services/discussion";
import PromotionService from "@/services/promotion";
import StyleService from "@/services/style";

import { _checkPromotionsCanBeApplyWithProduct } from "@/utlis/promotions";
export default {
  components: {
    ProductMB,
    ProductPC,
  },
  setup() {
    const route = useRoute();
    const product = ref(null);
    const state = reactive({
      productId: route.params.id,
      brand: {},
      discussion: {},
      promotions: [],
      promotionsValidated: [],
      products_similar: [],
      products_in_brand: [],
      posts: [],
    });

    getProduct();

    async function getProduct() {
      const response = await ProductService.fetchProduct(state.productId);

      if (response && response.data) {
        product.value = response.data;
        document.title = product.value.product;
        getBrand(product.value.company_id);
        getDiscussion();
        getRaletedPost();
        getProductSimilar();
        getProductInbrand();
        getPromotions();
      }
    }

    async function getBrand(id) {
      const response = await BrandService.fetchBrand(id);
      if (response.data) {
        state.brand = response.data;
      }
    }

    async function getDiscussion() {
      const response = await DiscussionService.fetchDiscussions(
        product.value.product_id,
        "p"
      );
      if (response && response.data) {
        state.discussion = response.data;
      }
    }

    async function getPromotions() {
      const response = await PromotionService.fetchPromotions({
        get_hidden: false,
        active: true,
        "expand[convert]": true,
        "expand[make_up]": true,
        sort_by: "position",
      });

      if (response && response.data) {
        state.promotions = response.data.promotions;
        state.promotionsValidated = _checkPromotionsCanBeApplyWithProduct(
          state.promotions,
          product.value
        );
      }
    }

    async function getProductInbrand() {
      const response = await ProductService.fetchProducts({
        company_id: product.value.company_id,
        limit: 20,
      });

      if (response && response.data) {
        state.products_in_brand = response.data.products;
      }
    }

    async function getProductSimilar() {
      const response = await ProductService.fetchProducts({
        cid: product.value.category_id,
        limit: 20,
      });

      if (response && response.data) {
        state.products_similar = response.data.products;
      }
    }

    async function getRaletedPost() {
      const response = await StyleService.fetchPosts({
        product_ids: product.value.product_id,
      });

      if (response && response.data) {
        state.posts = response.data.vnfd;
      }
    }

    onBeforeRouteUpdate((to, from) => {
      if (state.productId === to.params.id) return;

      state.brand = {};
      state.discussion = {};
      state.promotions = [];
      state.promotionsValidated = [];
      state.products_similar = [];
      state.products_in_brand = [];
      product.value = null;
      state.productId = to.params.id;
      getProduct();
    });

    provide("state", state);
    provide("product", product);

    return {
      state,
    };
  },
};
</script>
