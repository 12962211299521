<template>
  <div class="mag-container_review p_review_wrapper dosiin_mt-16" v-if="discussionObject">
    <div class="review-overview p_review_box">
      <h3>Đánh giá sản phẩm</h3>
      <div class="review-overview-inner">
        <div class="box-review r_details-left">
          <div class="review-overview-total p_review-total">
            <div class="rating-number rating-txt">
              {{ ratingFormat(discussionObject.average_rating) }}<span>trên 5</span>
            </div>
          </div>
          <div class="rating-star">
            <star-rating
              :rating="
                discussionObject.average_rating
                  ? parseInt(discussionObject.average_rating)
                  : 0
              "
              :star-size="16"
              :read-only="true"
              :show-rating="false"
              text-class="product-tem_social-text"
              active-color="#FFCC07"
              :star-points="[
                23,
                2,
                14,
                17,
                0,
                19,
                10,
                34,
                7,
                50,
                23,
                43,
                38,
                50,
                36,
                34,
                46,
                19,
                31,
                17,
              ]"
            />
            <p class="rating-review rating-cmt" v-if="discussionObject.search">
              {{ discussionObject.search.total_items }}<span>đánh giá</span>
            </p>
          </div>
        </div>
        <div class="r_details-middle">
          <ul class="rating-detail" v-if="discussionCount">
            <template v-for="(rating, index) in discussionCount">
              <li class="rating-detail-item" v-if="rating" :key="index">
                <span class="order-number">{{ rating.rating_value }}</span>
                <i class="dsi dsi-fill-star"></i>
                <div class="percentage-bar">
                  <span
                    class="percentage-bar-active"
                    :style="`width:${rating.percent}%`"
                  ></span>
                </div>
                {{ rating.percent }}%
              </li>
            </template>
          </ul>
        </div>
        <div class="r_details-right">
          <span class="rating-product">Đánh giá sản phẩm</span>
          <div class="box-rating-star p_rating-star">
            <star-rating
              :rating="rating"
              :star-size="28"
              :read-only="false"
              :show-rating="false"
              text-class="product-tem_social-text"
              active-color="#FFCC07"
              :star-points="[
                23,
                2,
                14,
                17,
                0,
                19,
                10,
                34,
                7,
                50,
                23,
                43,
                38,
                50,
                36,
                34,
                46,
                19,
                31,
                17,
              ]"
              @update:rating="setRating"
            />
          </div>
          <button class="primary-button dosiin_write-review" @click="openDiscusForm">
            <span class="primary-link_text">Viết đánh giá</span>
            <i class="dsi dsi-act-write"></i>
          </button>
        </div>
      </div>
    </div>

    <template v-if="discussionObject.search && discussionObject.search.total_items > 0">
      <div class="line"></div>
      <div class="tab-bar-filter_box" style="justify-content: flex-start">
        <!-- <div class="tab-bar-filter-item p_bg-filter">
                    <span class="text-item">Bộ lọc</span>
                    <div class="icon_filter">
                        <i class="dsi dsi-filter-setting"></i>
                    </div>
                </div> -->
        <!-- <div class="tab-bar-filter-item-icon">
                    <svg width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="1" height="32" fill="#E6E6E6"></rect>
                    </svg>
                </div> -->
        <div
          class="tab-bar-filter-item p_filter-item"
          :class="{ active: state.rating_value === null }"
          @click="
            state.rating_value = null;
            getPostDiscussion();
          "
        >
          <span class="text-item">Toàn bộ</span>
        </div>
        <div class="tab-bar-filter-item p_select_item dosiin_ml-2" @click="setSorting()">
          <span class="text-item">Thời gian</span>
          <i
            class="dsi"
            :class="state.sort_order === 'asc' ? 'dsi-drop-up' : 'dsi-drop-down'"
          ></i>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item dosiin_ml-2"
          :class="{ active: state.rating_value == 5 }"
          @click="
            state.rating_value = 5;
            getPostDiscussion();
          "
        >
          <span class="text-item dosiin_mr-2">5</span>
          <i class="dsi dsi-act-fill-star"></i>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item dosiin_ml-2"
          :class="{ active: state.rating_value == 4 }"
          @click="
            state.rating_value = 4;
            getPostDiscussion();
          "
        >
          <span class="text-item dosiin_mr-2">4</span>
          <i class="dsi dsi-act-fill-star"></i>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item dosiin_ml-2"
          :class="{ active: state.rating_value == 3 }"
          @click="
            state.rating_value = 3;
            getPostDiscussion();
          "
        >
          <span class="text-item dosiin_mr-2">3</span>
          <i class="dsi dsi-act-fill-star"></i>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item dosiin_ml-2"
          :class="{ active: state.rating_value == 2 }"
          @click="
            state.rating_value = 2;
            getPostDiscussion();
          "
        >
          <span class="text-item dosiin_mr-2">2</span>
          <i class="dsi dsi-act-fill-star"></i>
        </div>
        <div
          class="tab-bar-filter-item p_filter-item dosiin_ml-2"
          :class="{ active: state.rating_value == 1 }"
          @click="
            state.rating_value = 1;
            getPostDiscussion();
          "
        >
          <span class="text-item dosiin_mr-2">1</span>
          <i class="dsi dsi-act-fill-star"></i>
        </div>
      </div>
    </template>

    <div class="line"></div>
    <section
      class="customer-review-section"
      style="min-height: 398px"
      :style="
        state.posts.length === 0
          ? 'display: flex;justify-content: center;align-items: center;'
          : ''
      "
    >
      <DiscussionsList :discussions="state.posts" />

      <Pagination
        :wrapperClass="'pagination_details'"
        v-if="state.totalPage > 1 && !state.hidePagination"
        v-model="state.page"
        @update:modelValue="getPostDiscussion"
        :pages="state.totalPage"
      />
    </section>

    <CreateDiscussion
      v-model="state.showDiscussionForm"
      :rating="rating"
      :object="object"
      :objectType="'P'"
      :objectID="object.product_id"
      :discussion="state.discussion"
      @onCreated="createdDiscussion"
    />
  </div>
</template>

<script>
import CreateDiscussion from "@/components/modals/CreateDiscussion";
import DiscussionsList from "@/components/common/DiscussionsList";
import Pagination from "@/components/pagination/Pagination";
import StarRating from "vue-star-rating";
import DiscussionService from "@/services/discussion";

import { reactive, ref, toRef, toRefs, watch, inject } from "vue";

import { serialize } from "@/utlis/serialize";

export default {
  components: {
    Pagination,
    StarRating,
    CreateDiscussion,
    DiscussionsList,
  },
  props: {
    object: {
      type: Object,
      default: {},
    },
    discussion: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const state = reactive({
      showDiscussionForm: false,
      object_id: 0,
      object_type: "",
      loading: true,
      page: 1,
      totalPage: 0,
      posts: [],
      sort_by: null,
      sort_order: null,
      rating_value: null,
    });

    const rating = ref(0);
    const discussionObject = toRef(props, "discussion");
    let discussionCount = ref([]);
    const currentUser = inject("currentUser");
    const openModalLogin = inject("openModalLogin");

    watch(
      () => discussionObject.value,
      () => {
        state.posts = discussionObject.value.posts;
        discussionCount.value = discussionObject.value.count_rating;
        let total_items = parseInt(discussionObject.value.search.total_items);

        for (let i = 5; i > 0; i--) {
          let percent = 0;
          if (discussionCount.value[i] && discussionCount.value[i].total_rating > 0) {
            percent = Math.ceil(
              (parseInt(discussionCount.value[i].total_rating) / total_items) * 100
            );
          }
          if (!discussionCount.value[i])
            discussionCount.value[i] = {
              total_rating: 0,
              rating_value: i,
              percent,
            };
          else discussionCount.value[i].percent = percent;
        }

        // set pagination
        let totalItems = parseInt(discussionObject.value.search.total_items);
        state.object_id = discussionObject.value.object_id;
        state.object_type = discussionObject.value.object_type;
        state.page = discussionObject.value.search.page;
        state.totalPage = totalItems
          ? Math.ceil(totalItems / discussionObject.value.search.items_per_page)
          : 1;
      }
    );

    // watch([
    //     () => state.page
    // ], () => {
    //     //window.scrollTo( 0 , productBox.value.offsetTop);
    //     // props query change , update query
    //     getPostDiscussion();
    // })

    watch([() => state.rating_value], () => {
      state.page = 1;
    });

    async function getPostDiscussion() {
      const response = await DiscussionService.fetchPostDiscussion(state.object_id, {
        type: state.object_type,
        page: state.page,
        rating_value: state.rating_value ? state.rating_value : null,
        sort_by: state.sort_by,
        sort_order: state.sort_order,
      });

      if (response && response.data) {
        state.posts = response.data.posts;
        state.rating_value = response.data.search.rating_value
          ? response.data.search.rating_value
          : null;
      }
    }

    const openDiscusForm = () => {
      if (!currentUser.value) {
        openModalLogin();
        return;
      }

      state.showDiscussionForm = true;
    };

    const setSorting = () => {
      if (!state.sort_by && !state.sort_order) {
        state.sort_by = "timestamp";
        state.sort_order = "asc";
      } else {
        state.sort_by = null;
        state.sort_order = null;
      }

      getPostDiscussion();
    };
    const setRating = (ratingValue) => {
      if (!currentUser.value) {
        openModalLogin();
        return;
      }
      rating.value = ratingValue;
      state.showDiscussionForm = true;
    };

    const createdDiscussion = (discussion) => {
      state.posts.unshift(discussion);
      state.showDiscussionForm = false;
    };

    return {
      state,
      rating,
      discussionObject,
      discussionCount,
      setSorting,
      setRating,
      openDiscusForm,
      getPostDiscussion,
      createdDiscussion,
      ...toRefs(props),
    };
  },
};
</script>
