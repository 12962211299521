<template>
  <Modal2 v-model="show" @close="closeMoDal" :modalTypeClass="'modal-medium'">
    <template v-slot:header>
      <h3>Viết đánh giá</h3>
    </template>
    <template v-slot:body>
      <div class="product-detail-write-review">
        <div class="product-detail-write-review-section">
          <form id="product-detail-review-form" method="POST">
            <div class="dosiin_pd-16 dosiin_comment-inner scroll-box-modal">
              <div class="list-item product-list-item" v-if="objectType == 'P'">
                <a class="list-item_img">
                  <img
                    class="product-sm-item_img"
                    v-lazy="_productThumbnail(object.main_pair)"
                    :alt="object.product"
                    :title="object.product"
                    width="76"
                    height="76"
                  />
                </a>
                <dl class="list-item_summary">
                  <dt>
                    <a
                      class="list-item_brand"
                      title="Dosiin product"
                      v-text="object.company_name"
                    ></a>
                  </dt>
                  <dd class="list-item_name" v-text="object.product"></dd>
                </dl>
              </div>

              <div class="rating_wrapper">
                <div class="rating-stars-list rating_item">
                  <h5>Xếp hạng sản phẩm</h5>
                  <fieldset class="rating-stars-wrapper">
                    <star-rating
                      :rating="rating"
                      :star-size="28"
                      :read-only="false"
                      :show-rating="false"
                      text-class="product-tem_social-text"
                      active-color="#FFCC07"
                      :star-points="[
                        23,
                        2,
                        14,
                        17,
                        0,
                        19,
                        10,
                        34,
                        7,
                        50,
                        23,
                        43,
                        38,
                        50,
                        36,
                        34,
                        46,
                        19,
                        31,
                        17,
                      ]"
                      @update:rating="setRating"
                    />
                  </fieldset>

                  <div class="rating-stars-note">Chạm để đánh giá</div>
                </div>
                <div class="selection-buttons w-3 selection-buttons-review rating_item">
                  <input
                    v-model="discusForm.reviewText"
                    class="selection-buttons_item input-radio"
                    id="reviewText"
                    type="radio"
                    name="reviewText"
                    value="T"
                  />
                  <label class="rating_item-tab" for="reviewText"
                    ><span class="text text-dark-md">Chật</span></label
                  >
                  <input
                    v-model="discusForm.reviewText"
                    class="selection-buttons_item input-radio"
                    id="reviewText-1"
                    type="radio"
                    name="reviewText"
                    value="C"
                  />
                  <label class="rating_item-tab" for="reviewText-1"
                    ><span class="text text-dark-md">Thoải mái</span></label
                  >
                  <input
                    v-model="discusForm.reviewText"
                    class="selection-buttons_item input-radio"
                    id="reviewText-2"
                    type="radio"
                    name="review-info"
                    value="W"
                  />
                  <label class="rating_item-tab" for="reviewText-2"
                    ><span class="text text-dark-md">Rộng</span></label
                  >

                  <div class="price-range">
                    <div class="input-container">
                      <i class="text-grey-sm unit">KG</i>
                      <input
                        class="input-text"
                        type="text"
                        v-model="discusForm.kg"
                        placeholder="70"
                        maxlength="11"
                      />
                    </div>
                    <span class="concat">~</span>
                    <div class="input-container">
                      <i class="text-grey-sm unit">CM</i>
                      <input
                        class="input-text"
                        type="text"
                        v-model="discusForm.cm"
                        placeholder="175"
                        name="filterMaxPrice"
                        maxlength="11"
                      />
                    </div>
                  </div>
                  <div class="rating-stars-note dosiin_mt-2">
                    Độ phù hợp sản phẩm đối với bạn
                  </div>
                </div>
              </div>
              <div
                class="button-box-comment-reply center button-box-comment-reply-wrapper"
                v-bind="getRootProps()"
              >
                <input
                  class="hidden"
                  v-bind="getInputProps()"
                  type="file"
                  name="images_comment[]"
                  multiple
                  hidden
                />
                <label for="images_comment_hidden" @click="open">
                  <span class="file-button">
                    <i class="dsi dsi-camera-plus dsi-s32"></i>
                  </span>
                  <p class="file-box" id="file-name"></p>
                </label>
                <div class="rating-stars-note">Chọn hình ảnh hoặc kéo thả để đăng</div>
                <div class="images_attach_review" v-if="state.previewImages.length > 0">
                  <div
                    class="item-product"
                    v-for="(image, index) in state.previewImages"
                    :key="index"
                    style="width: 100px"
                  >
                    <template v-if="image">
                      <img
                        class="images-review"
                        width="100"
                        height="100"
                        :src="image"
                        style="position: relative"
                      />
                      <button
                        type="button"
                        class="button"
                        @click.prevent="removeImage(index)"
                      >
                        <img
                          class="icon-close"
                          width="30"
                          height="30"
                          :src="_closeButton"
                        />
                      </button>
                    </template>
                  </div>
                </div>
              </div>
              <div class="survey">
                <div class="value_cmt">
                  <p>Đánh giá</p>
                  <span>{{ discusForm.message.length }} / 200</span>
                </div>
                <textarea
                  v-model="discusForm.message"
                  name="comment"
                  placeholder="Chia sẻ cảm nghĩ của bạn ở đây"
                  maxlength="255"
                >
                </textarea>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="confirm-btn-wrapper sticky-bottom">
        <button
          class="button primary-button confirm-btn"
          type="button"
          @click="createDiscussion"
        >
          <span v-if="!state.createLoading" class="primary-link_text">Đánh giá</span>
          <pulse-loader
            v-else
            :loading="state.createLoading"
            color="#000000"
          ></pulse-loader>
        </button>
      </div>
    </template>
  </Modal2>
</template>

<script>
import Modal2 from "@/components/modals/Modal2";
import StarRating from "vue-star-rating";
import ProductItemHorizon from "@/components/product/ProductItemHorizon";
import discussionService from "@/services/discussion";

import { ref, watch, toRefs, reactive, inject } from "vue";
import { useDropzone } from "vue3-dropzone";
import { _convertFileToBase64 } from "@/utlis/dom";
import { useToast } from "vue-toastification";

export default {
  components: {
    Modal2,
    ProductItemHorizon,
    StarRating,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    discussion: {
      type: Object,
      default: {},
    },
    rating: {
      type: Number,
    },
    object: {
      type: Object,
      default: {},
    },
    objectType: {
      type: String,
      default: "P",
    },
    objectID: {
      type: [String, Number],
    },
  },
  emits: ["onCreated", "update:modelValue"],
  setup(props, { emit }) {
    const toast = useToast();
    const state = reactive({
      init: false,
      createLoading: false,
      uploadedImages: [],
      previewImages: [],
    });

    const show = ref(props.modalValue);
    const currentUser = inject("currentUser");
    const discusForm = reactive({
      user_id: 0,
      name: "",
      message: "",
      object_type: "",
      object_id: 0,
      reviewText: "C",
      kg: 0,
      cm: 0,
      rating_value: 5,
      images_comment: [],
    });

    // watcher
    watch(
      () => props.modelValue,
      (isOpen) => {
        show.value = isOpen;
      }
    );

    watch(
      () => props.rating,
      () => {
        discusForm.rating_value = props.rating;
      }
    );

    const closeMoDal = () => {
      show.value = !show.value;
      emit("update:modelValue", show.value);
    };

    const createDiscussion = async () => {
      if (state.init === true) {
        toast.warning("Bạn đã bình luận sản phẩm này");
        return;
      }
      // validate
      if (discusForm.message.length === 0) {
        toast.warning("Vui lòng nhập nội dung đánh giá bạn nhé");
        return;
      }

      if (!currentUser) {
        toast.warning("Vui lòng đăng nhập để gửi đánh giá");
        return;
      }

      state.createLoading = true;

      const formData = new FormData();
      // fill data
      formData.append("user_id", currentUser.value.user_id);
      formData.append("name", currentUser.value.firstname);
      formData.append("message", discusForm.message);
      formData.append("reviewText", discusForm.reviewText);
      formData.append("kg", discusForm.kg);
      formData.append("cm", discusForm.cm);
      formData.append("rating_value", discusForm.rating_value);
      formData.append("object_type", props.objectType);
      formData.append("object_id", props.objectID);

      for (var x = 0; x < state.uploadedImages.length; x++) {
        // append files as array to the form, feel free to change the array name
        formData.append("images_comment[" + x + "]", state.uploadedImages[x]);
      }

      let response = await discussionService.createDiscussionWithImage(formData);
      if (response && response.data) {
        state.init = true;
        emit("onCreated", response.data.post);

        closeMoDal();

        toast.success("Cám ơn bạn đã bình luận sản phẩm");
      }

      //reset state data
      //discusForm.message = '';

      state.createLoading = false;
    };

    function onDrop(acceptFiles, rejectReasons) {
      state.uploadedImages.push(...acceptFiles);
      previewFiles(acceptFiles);
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDrop,
      accept: "image/x-png,image/gif,image/jpeg,image/png",
      noClick: true,
      maxFiles: 10,
    });

    const previewFiles = async (files) => {
      for (var x = 0; x < files.length; x++) {
        // append files as array to the form, feel free to change the array name
        const image = await _convertFileToBase64(files[x]);
        state.previewImages.push(image);
      }
    };

    const removeImage = (index) => {
      state.uploadedImages = state.uploadedImages.filter((item, i) => {
        return i !== index;
      });
      state.previewImages = state.previewImages.filter((item, i) => {
        return i !== index;
      });
    };

    const setRating = (ratingValue) => {
      discusForm.rating_value = ratingValue;
    };

    return {
      state,
      show,
      discusForm,
      ...toRefs(props),
      closeMoDal,
      setRating,
      createDiscussion,
      //dropzone
      onDrop,
      removeImage,
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>
