<template>
    <div>
        <form 
            class="passersby" 
            name="dosiin_checkout-register-address"
            novalidate
        >
            <h3 class="title">Khách vãng lai</h3>
            <input type="hidden" name="ship_to_another" value="1" />
            <input type="hidden" name="shipping_dosiin" value="{$shipping_dosiin}" />
            
            <div class="form-group grid-item" :class="{ 'error' : errors.b_firstname}">
                <input class="form-control" 
                    v-model="userData.b_firstname"
                    id="profile_name" 
                    type="text" 
                    name="user_data[b_firstname]" 
                >
                <label for="profile_name" class="cm-required">Tên người nhận</label>
            </div>

            <div class="form-group grid-item" :class="{ 'error' : errors.email}">
                <input 
                    v-model="userData.email"
                    class="form-control" 
                    id="profile_email" 
                    name="user_data[email]" 
                    type="text"
                />
                <label for="profile_email">Email</label>
            </div>
            
            <div class="form-group grid-item" :class="{ 'error' : errors.b_phone}">
                <input 
                    v-model="userData.b_phone"
                    class="form-control" 
                    id="profile_phone"  
                    name="user_data[b_phone]" 
                    type="text" 
                />
                <label for="profile_phone">Số điện thoại</label>
            </div>

            <div class="form-group grid-item" :class="{ 'error' : errors.b_address}">
                <input 
                    v-model="userData.b_address"
                    class="form-control" 
                    id="address" 
                    name="user_data[b_address]" 
                    type="text"
                />
                <label for="address" class="cm-required">Địa chỉ nhà</label>
            </div>

            <div class="form-group grid-item" :class="{ 'error' : errors.b_state}">
                <button class="dosiin_w-100" @click.prevent="showAddressPicker = true" type="button" style="height: 100%;text-align: left;">
                    <input 
                        v-model="userData.b_state"
                        id="profile_state" 
                        type="hidden" 
                        name="user_data[b_state]" 
                    />
                    <span class="form-control" style="position: absolute;bottom: 15%;" v-text="addressTitle.state"></span>
                    <label for="profile_state"  style="font-size: 12px;line-height: 150%;top: 10%;transform: unset;">Tỉnh/Thành phố</label>
                    <div class="edit-btn_profile">
                        <i class="dsi dsi-s16 dsi-chevron-right"></i>
                    </div>
                </button>
            </div>
            <div class="form-group grid-item" :class="{ 'error' : errors.b_maqh}">
                <button class="dosiin_w-100" @click.prevent="showAddressPicker = true" type="button" style="height: 100%;text-align: left;">
                    <input 
                        v-model="userData.b_maqh"
                        id="profile_district" 
                        type="hidden" 
                        name="user_data[b_maqh]" 
                    />
                    <span class="form-control" style="position: absolute;bottom: 15%;" v-text="addressTitle.b_maqh"></span>
                    <label for="profile_district" style="font-size: 12px;line-height: 150%;top: 10%;transform: unset;">Quận/Huyện</label>
                    <div class="edit-btn_profile">
                        <i class="dsi dsi-s16 dsi-chevron-right"></i>
                    </div>
                </button>
            </div>

            <div class="form-group grid-item">
                <button class="dosiin_w-100" @click.prevent="showAddressPicker = true" type="button" style="height: 100%;text-align: left;">
                    <input 
                        v-model="userData.b_xaid"
                        id="profile_ward" 
                        type="hidden" 
                        name="user_data[b_xaid]" 
                    />
                    <span class="form-control" style="position: absolute;bottom: 15%;" v-text="addressTitle.b_xaid"></span> 
                    <label for="profile_ward"  style="font-size: 12px;line-height: 150%;top: 10%;transform: unset;">Phường/Xã</label>
                    <div class="edit-btn_profile">
                        <i class="dsi dsi-s16 dsi-chevron-right"></i>
                    </div>
                </button>
            </div>
        </form>

        <PickerAddress 
            v-model="showAddressPicker" 
            @update:modelValue="newValue => showAddressPicker = newValue"
            @updateUserAddress="updateUserAddress"
        />
    </div>
</template>

<script>
    import PickerAddress from '@/components/modals/PickerAddress';
    import { 
        _isEmail, 
        _isVietnamesePhoneNumber, 
        _minLength 
    }  from '@/utlis/validation';

    import { reactive , ref ,watch , toRef} from 'vue';

    export default {
        components:{
            PickerAddress
        },
        emits : ['update:modelValue'],
        props:{
            modelValue :{
                type : Object,
                default : {}
            }
        },
        setup(props , { emit }) {

            const showAddressPicker = ref(false);

            const userData = reactive({
                'b_firstname' : toRef(props.modelValue , 'b_firstname'),
                'email'       : toRef(props.modelValue , 'email'),
                'b_phone'     : toRef(props.modelValue, 'b_phone'),
                'b_address'   : props.modelValue.b_address,
                'b_state'     : props.modelValue.b_state,
                'b_maqh'       : props.modelValue.b_maqh,
                'b_xaid'      : props.modelValue.b_xaid
            });

            const errors = reactive({
                'b_firstname' : false,
                'email'       : false,
                'b_phone'     : false,
                'b_address'   : false,
                'b_state'     : false,
                'b_maqh'       : false,
                'b_xaid'      : false
            });

            const addressTitle = reactive({ 
                state  : '',
                b_maqh : '',
                b_xaid : ''
            });

            // watch validations form 
            watch(() => userData.b_firstname , () => {
                if(userData.b_firstname.length === 0 ) {
                    errors.b_firstname = true;
                    return;
                }

                errors.b_firstname = false;
                emit('update:modelValue', userData);
            });

            watch(() => userData.email , () => {

                if(userData.email.length === 0 || !_isEmail(userData.email)) {
                    errors.email = true;
                    return;
                }

                errors.email = false;
                emit('update:modelValue', userData);
            });

            watch(() => userData.b_phone , () => {
                if(userData.b_phone.length === 0 || !_isVietnamesePhoneNumber(userData.b_phone)) {
                    errors.b_phone = true;
                    return;
                }

                errors.b_phone = false;
                emit('update:modelValue', userData);
            });

            watch(() => userData.b_address , () => {
                if(userData.b_address.length === 0) {
                    errors.b_address = true;
                    return;
                }

                errors.b_address = false;
                emit('update:modelValue', userData);
            });

            watch(() => userData.b_state , () => {
                emit('update:modelValue', userData);
            });

            watch(() => userData.b_maqh , () => {
                emit('update:modelValue', userData);
            });

            watch(() => userData.b_xaid , () => {
                emit('update:modelValue', userData);
            });


            const updateUserAddress = (userAddress) => {                
                if(userAddress){
                    addressTitle.state  = userAddress.city.state;
                    addressTitle.b_maqh = userAddress.district.name;
                    addressTitle.b_xaid = userAddress.ward.name;

                    userData.b_state = userAddress.city.state_id;
                    userData.b_maqh   = userAddress.district.maqh;
                    userData.b_xaid  = userAddress.ward.xaid;
                }
            }

            
            return {
                showAddressPicker,
                userData,
                errors,
                addressTitle,
                // methods
                updateUserAddress
            }
        },
    }
</script>