<template>
    <Teleport to="#modals">
        <Modal2
            v-model="show"
            @close="closeMoDal"
            :modalTypeClass="'modal-small'"
            :overlay="false"
        >
            <template v-slot:header>
                <h3>Khuyến mãi</h3>
            </template>
            <template v-slot:body>
                
                <div class="scroll-box-modal scroll-box-modal-coupons">
                    <div class="coupon-form" v-if="currentUser">
                        <p>
                            Chào <b v-text="currentUser.first_name"></b>, đây là danh sách các <b>phiếu giảm giá </b>mà bạn có thể áp dụng. 
                            Bạn có thể dùng phiếu giảm giá của <b>Dosiin</b> gộp với phiếu giảm giá của <b>Thương Hiệu</b> bạn mua (nếu thoả điều kiện)
                        </p>
                    </div>
                    <PromotionsList
                        :promotions="promotions"
                    />
                </div>
            </template>
        </Modal2>
    </Teleport>
</template>

<script>
    import Modal2 from '@/components/modals/Modal2';
    import { toRefs , ref , watch , inject } from 'vue';
    import PromotionsList from '@/components/common/PromotionsList';

    export default {
        components:{
            Modal2,
            PromotionsList
        },
        emits : ['update:modelValue'],
        props:{
            modelValue : {
                type : Boolean,
                default : false
            },
            promotions : {
                type : Array,
                default : []
            },
            buttonType : {
                type : String ,
                default : 'use'
            }
        },
        setup(props , {emit}) {
            const currentUser = inject('currentUser');
            const show = ref(props.modalValue);

            watch(() => props.modelValue, (isOpen) => {
                show.value = isOpen;
            });

            const closeMoDal = () => {
                show.value = !show.value
                emit('update:modelValue',show.value);
            }

            return {
                show,
                closeMoDal,
                currentUser,
                ...toRefs(props)
            }
        },
    }
</script>