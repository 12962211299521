<template>
    <ais-pagination>
        <template
            v-slot="{
                currentRefinement,
                pages,
                isFirstPage,
                isLastPage,
                refine,
                createURL
            }"
        >
            <ul class="pagination d-flex" ref="pagination">  
                <li class="page-item prev" v-if="!isFirstPage">
                    <a
                        class="page-link"
                        :class="$style.block"
                        :href="createURL(currentRefinement - 1)"
                        @click.prevent="refine(currentRefinement - 1);changeCurrentPage()"
                    >
                        <i class="dsi dsi-chevron-left" :class="{ 'dsi-s24' : layout != 4}"></i>
                    </a>
                </li>
                <li :class="page === currentRefinement ? 'page-item active' : 'page-item medium'" v-for="page in pages" :key="page">
                    <a
                        :href="createURL(page)"
                        :class="page === currentRefinement ? 'page-link' : ''"
                        @click.prevent="refine(page);changeCurrentPage()"
                    >
                        {{ page + 1 }}
                    </a>
                </li>
                <li class="page-item next" v-if="!isLastPage">
                    <a
                        class="page-link"
                        :class="$style.block"
                        :href="createURL(currentRefinement + 1)"
                        @click.prevent="refine(currentRefinement + 1);changeCurrentPage()"
                    >
                        <i class="dsi dsi-chevron-right" :class="{ 'dsi-s24' : layout != 4}"></i>
                    </a>
                </li>
            </ul>
        </template>
    </ais-pagination>
</template>

<script>
import {ref} from 'vue';
export default {
    setup() {
        const pagination = ref(null);

        const changeCurrentPage = () => {
            const targetEle = pagination.value.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
            setTimeout(() => {
                window.scrollTo({
                    left : 0 , 
                    top : Tygh.layout == 4 ? targetEle.offsetTop - 50 : targetEle.offsetTop - 126,
                    behavior: "smooth"
                }); 
            }, 800);
                       
        }

        return {
            pagination,
            changeCurrentPage
        }
    },
}
</script>
<style module>
    .block{
        display: block;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
</style>