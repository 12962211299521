import Api from '@/services/Api';

export default {
    fetchCities(){
        return Api().get(`4.0/province`);
    },

    fetchDistricts(matp){
        return Api().get(`4.0/province?getDistricts=true&matp=${matp}`);
    },

    fetchWards(maqh){
        return Api().get(`4.0/province?getWards=true&maqh=${maqh}`);
    }
}