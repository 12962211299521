// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Product_dark_2T9ke{--popper-theme-background-color:#333;--popper-theme-background-color-hover:#333;--popper-theme-text-color:#fff;--popper-theme-border-width:0px;--popper-theme-border-radius:6px;--popper-theme-padding:16px;--popper-theme-box-shadow:0 6px 30px -6px rgba(0,0,0,0.25);font-size:12px}.Product_warningAddToCart_2ubDJ{background-color:rgba(255,0,32,.03)}", ""]);
// Exports
exports.locals = {
	"dark": "Product_dark_2T9ke",
	"warningAddToCart": "Product_warningAddToCart_2ubDJ"
};
module.exports = exports;
